import { Form, FormField, FormFieldSet } from '@aignostics/components';
import React, { useMemo } from 'react';

export interface CreateSubProjectFormValues {
  name: string;
  description: string;
  showCsv: boolean;
}

interface CreateSubProjectFormProps {
  onSubmit: (values: CreateSubProjectFormValues) => void;
  isSubmitting: boolean;
}

const CreateSubProjectForm = ({
  onSubmit,
  isSubmitting,
}: CreateSubProjectFormProps): JSX.Element => {
  const formFields = useMemo<(FormField | FormFieldSet)[]>(
    () => [
      {
        label: 'Subproject name',
        id: 'name',
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        label: 'Description',
        id: 'description',
        value: '',
        type: 'textarea',
      },
      {
        id: 'allowing',
        label: 'Allow the following:',
        type: 'fieldset',
        align: 'vertical',
        fields: [
          {
            label: 'Visibility',
            id: 'isVisible',
            value: false,
            type: 'checkbox',
            inline: true,
          },
          {
            label: 'CSV file',
            id: 'showCsv',
            value: false,
            type: 'checkbox',
            inline: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <Form
      aria-label="Create subproject"
      onSubmit={(values) => {
        onSubmit(values as unknown as CreateSubProjectFormValues);
      }}
      loading={isSubmitting}
      fields={formFields}
      reset
      submitButtonText="Create"
    />
  );
};

export default CreateSubProjectForm;
