import { Dispatch, SetStateAction, useState } from 'react';
export const prefix = 'P+';

export type SortByDirection = 'asc' | 'desc';

export type SortBy<SortByOptions> = {
  column: SortByOptions | null;
  sortDirection: SortByDirection | null;
} | null;

export type SetSortBy<SortByOptions> = Dispatch<
  SetStateAction<SortBy<SortByOptions>>
>;

//** SortByOptions are defined in the table component where wee use the useSortBy hook */
export type UseSortBy<SortByOptions> = {
  sortBy: SortBy<SortByOptions>;
  setSortByOptions: (sortByOption: SortByOptions) => void;
};

/**
 * useSortBy renders sorting icon and handles sorting
 * @param defaultSortBy
 * @param defaultSortDirection
 * @returns
 */

/**
 * SortBy hook that handles sorting direction and sorting Icon
 * @param defaultSortBy
 * @param defaultSortDirection undefined can be used to have no sorting selected
 * @returns
 */
export function useSortBy<SortByOptions>(
  defaultSortBy: SortBy<SortByOptions>
): UseSortBy<SortByOptions> {
  const [sortBy, setSortBy] = useState<SortBy<SortByOptions>>(
    (): SortBy<SortByOptions> => {
      const persisted = sessionStorage.getItem(
        `${prefix}${window.location.pathname}`
      );
      if (persisted) {
        const persistedParams = new URLSearchParams(persisted);
        const persistedSortBy = persistedParams.get('sortBy');
        const persistedSortDirection = persistedParams.get('sortDirection');

        if (persistedSortBy) {
          return {
            column: persistedSortBy as unknown as SortByOptions,
            sortDirection: persistedSortDirection as SortByDirection,
          };
        }
      }
      return defaultSortBy;
    }
  );

  /**
   * sets sortByOption and toggles sortDirection
   * @param sortByOption
   */
  const setSortByOptions = (sortByOption: SortByOptions) => {
    if (sortBy?.sortDirection === 'asc' && sortByOption === sortBy.column) {
      setSortBy({ column: sortByOption, sortDirection: 'desc' });
    } else {
      setSortBy({
        column: sortByOption,
        sortDirection: 'asc',
      });
    }
  };

  return {
    sortBy,
    setSortByOptions,
  };
}

export function getSortDirection<SortByOptions>(
  sortBy?: SortBy<SortByOptions>,
  headerSortingBy?: SortByOptions
): SortByDirection | null {
  if (sortBy?.column === headerSortingBy && sortBy?.column) {
    return sortBy.sortDirection;
  }
  return null;
}
