import {
  Button,
  Disclosure,
  Modal,
  Placeholder,
  Section,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { BlockTable } from '../../../../../components';
import {
  InteractiveOverlayAll,
  InteractiveOverlayWsi,
} from '../../AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.types';
import {
  StaticOverlayAll,
  StaticOverlayWsi,
} from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { UnsavedChangesModal } from '../../components/UnsavedChanges.modal';
import { OverlayAssignment, OverlayToggle, TableData } from '../Overlays.types';
import useBaseColumns from '../hooks/useBaseColumns';
import useOverlayColumns from '../hooks/useOverlayColumns';
import useToggleHandler from '../hooks/useToggleHandler';
import { useUniqueOverlays } from '../hooks/useUniqueOverlays';
import {
  $ModalContent,
  $ModalFooter,
  $ModalTitle,
} from './OverlayMatrixView.modal.style';
import {
  populateUpdates,
  updateWsisAllOverlays,
  useTableData,
} from './OverlayMatrixView.utils';

export interface OverlayMatrixViewModalProps {
  wsis: (InteractiveOverlayWsi | StaticOverlayWsi)[];
  matrixViewModal: Disclosure<unknown>;
  subprojectId: string;
  organizationUuid: string;
  userRole: OrganizationRole;
  type: 'interactive' | 'static';
}

export type Updates = Array<{ value: boolean; update: OverlayAssignment }>;

export type TableOverlays = TableData<
  OverlayToggle<InteractiveOverlayAll | StaticOverlayAll>
>[];
export const OverlayMatrixViewModal = ({
  matrixViewModal,
  wsis,
  subprojectId,
  organizationUuid,
  userRole,
  type,
}: OverlayMatrixViewModalProps): JSX.Element => {
  // allUpdates is what we submit on save
  const [allUpdates, setAllUpdates] = useState<Updates>([]);
  const [wsisAllOverlays, setWsisAllOverlays] = useState<TableOverlays>([]);

  const overlaysAll = useMemo(
    () =>
      wsis
        .flatMap((wsi) => {
          if ('taggersAll' in wsi && Array.isArray(wsi.taggersAll)) {
            return wsi.taggersAll;
          } else if ('overlaysAll' in wsi && Array.isArray(wsi.overlaysAll)) {
            return wsi.overlaysAll;
          }
          return [];
        })
        .sort((a, b) => (a.sort || 0) - (b.sort || 0)),
    [wsis]
  );
  const overlaysAssigned = useMemo(
    () =>
      wsis.flatMap((wsi) => {
        if ('taggersAssigned' in wsi && Array.isArray(wsi.taggersAssigned)) {
          return wsi.taggersAssigned;
        } else if (
          'overlaysAssigned' in wsi &&
          Array.isArray(wsi.overlaysAssigned)
        ) {
          return wsi.overlaysAssigned;
        }
        return [];
      }),
    [wsis]
  );

  const unsavedChangesModal = useDisclosure();

  const wsisAllTaggersTableData = useTableData(wsis);

  useEffect(() => {
    setWsisAllOverlays(wsisAllTaggersTableData);
  }, [wsis, wsisAllTaggersTableData]);

  function getOverlayType(
    wsis: (InteractiveOverlayWsi | StaticOverlayWsi)[]
  ): 'interactive' | 'static' {
    // determine if its a static or interactive overlay
    if (wsis.some((wsi) => 'taggersAll' in wsi)) {
      return 'interactive';
    } else if (wsis.some((wsi) => 'overlaysAll' in wsi)) {
      return 'static';
    }
    throw new Error('Unknown overlay type');
  }

  const baseColumns = useBaseColumns({ organizationUuid });

  const uniqueOverlays = useUniqueOverlays(overlaysAll, overlaysAssigned, type);

  const overlayType = getOverlayType(wsis);
  const [onChange, { loading: mutationLoading, error: mutationError }] =
    useToggleHandler(overlayType, subprojectId);

  const onToggleChange = (
    value: boolean,
    toggledWsisOverlays: OverlayAssignment[]
  ) => {
    setAllUpdates(populateUpdates(value, toggledWsisOverlays, allUpdates));
    const res = updateWsisAllOverlays(
      value,
      toggledWsisOverlays,
      wsisAllOverlays
    );
    setWsisAllOverlays(res);
  };

  const overlayColumns = useOverlayColumns(
    uniqueOverlays,
    onToggleChange,
    userRole
  );
  const tableColumns = useMemo(
    () => [...baseColumns, ...overlayColumns],
    [baseColumns, overlayColumns]
  );

  const onSave = () => {
    const activeOverlays: OverlayAssignment[] = [];
    const inactiveOverlays: OverlayAssignment[] = [];
    allUpdates.forEach((o) => {
      if (o.value) {
        activeOverlays.push(o.update);
      } else {
        inactiveOverlays.push(o.update);
      }
    });
    if (activeOverlays.length > 0) {
      onChange(true, activeOverlays);
    }
    if (inactiveOverlays.length > 0) {
      onChange(false, inactiveOverlays);
    }
    setAllUpdates([]);
    matrixViewModal.close();
    unsavedChangesModal.close();
  };

  const onCancel = () => {
    if (isEqual(wsisAllTaggersTableData, wsisAllOverlays)) {
      setAllUpdates([]);
      matrixViewModal.close();
    } else {
      unsavedChangesModal.open();
    }
  };

  const OnUnsavedChangesModalClose = () => {
    unsavedChangesModal.close();
  };

  const hasChanges = !isEqual(wsisAllTaggersTableData, wsisAllOverlays);
  const onCloseModal = () => {
    if (hasChanges) {
      unsavedChangesModal.open();
    } else {
      setAllUpdates([]);
      matrixViewModal.close();
    }
  };

  return (
    <>
      <Modal
        isVisible={matrixViewModal.isOpen}
        onClose={onCloseModal}
        size={'large'}
        hasCloseButton
      >
        <Section loading={mutationLoading} error={mutationError}>
          <$ModalContent>
            <$ModalTitle>Side-by-side view</$ModalTitle>

            {wsisAllOverlays.length > 0 ? (
              <BlockTable columns={tableColumns} data={wsisAllOverlays} />
            ) : (
              <Placeholder title="No interactive overlays" />
            )}
          </$ModalContent>
        </Section>
        <$ModalFooter>
          {/* Save should be enabled only if there is change */}
          <Button onClick={onCancel} variant="primaryOutline">
            Cancel
          </Button>
          <Button onClick={onSave} disabled={!hasChanges}>
            Save changes
          </Button>
        </$ModalFooter>
      </Modal>
      <UnsavedChangesModal
        isVisible={unsavedChangesModal.isOpen}
        onClose={OnUnsavedChangesModalClose}
        onDiscard={() => {
          unsavedChangesModal.close();
          matrixViewModal.close();
        }}
        onSave={onSave}
      />
    </>
  );
};
