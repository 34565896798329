import { Modal, useSnackbarMutations } from '@aignostics/components';
import { interactiveOverlayColorPalette } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useState } from 'react';
import { moveArray } from '../../../../../../utils/DynamicList/DynamicList';
import { useDynamicList } from '../../../../../../utils/DynamicList/dynamic';
import DynamicSizeItem from '../DynamicItem';
import {
  $ContentWrapper,
  $ListHeader,
  $ModalSubTitle,
  $ModalTitle,
} from './ColorSelectorModal.styles';
import {
  ColorsSelectorModalProps,
  FetchTagsData,
  Tag,
} from './ColorSelectorModal.types';
import { TagItem } from './TagItem';
import { FETCH_TAGS_QUERY } from './graphql/FETCH_TAGS_QUERY';
import { SET_TAGS_CONFIG_MUTATION } from './graphql/SET_TAGS_CONFIG_MUTATION';

export const ColorsSelectorModal = ({
  modalData,
  onClose,
  subProjectId,
}: ColorsSelectorModalProps): ReactElement => {
  const [tags, setTags] = useState<Tag[]>([]);
  const { addSnackbar } = useSnackbarMutations();

  const [setTagsConfig] = useMutation(SET_TAGS_CONFIG_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_TAGS_QUERY,
        variables: {
          subProjectId,
          tagger: modalData?.id,
        },
      },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error' });
    },
  });

  const onCloseModal = async () => {
    onClose();

    if (tags.length) {
      await setTagsConfig({
        variables: {
          tags: tags.map(({ id, sort, color }) => {
            return {
              id,
              sort,
              color,
            };
          }),
          subProjectId,
          taggerId: modalData?.id,
        },
      });
    }
    setTags([]);
  };
  const { loading } = useQuery<FetchTagsData>(FETCH_TAGS_QUERY, {
    skip: !modalData,
    variables: {
      subProjectId,
      tagger: modalData?.id,
    },

    onCompleted: (data) => {
      const newItems = data.subProject.tags.map((item, index) => {
        return {
          ...item,
          color:
            item?.color ||
            interactiveOverlayColorPalette[
              index % interactiveOverlayColorPalette.length
            ],
        };
      });

      setTags(newItems);
    },
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error' });
    },
  });

  const onPositionUpdate = useCallback(
    (startIndex: number, endIndex: number) => {
      const newItems = [...moveArray(tags, startIndex, endIndex)].map(
        (item, index) => {
          return {
            ...item,
            sort: index,
          };
        }
      );

      setTags(newItems);
    },
    [tags, setTags]
  );

  const updateTagColor = useCallback(
    (index) => (color: string) => {
      const newItems = [...tags];
      newItems[index] = {
        ...tags[index],
        color,
      };
      setTags(newItems);
    },
    [tags, setTags]
  );

  const dynamicListItemProps = useDynamicList({
    items: tags,
    onPositionUpdate,
  });

  return (
    <Modal
      isVisible={modalData?.id !== undefined}
      hasCloseButton
      onClose={onCloseModal}
      size="large"
    >
      <$ContentWrapper>
        <$ModalTitle>{modalData?.originalName}</$ModalTitle>
        <$ModalSubTitle>{tags.length} Classes</$ModalSubTitle>
        <div>
          <$ListHeader>
            <p>Class name</p>
            <p>Class color</p>
          </$ListHeader>
          {!loading &&
            tags.map((tag: Tag, index: number) => {
              return (
                <DynamicSizeItem
                  key={tag.id}
                  index={index}
                  itemProps={dynamicListItemProps}
                >
                  <TagItem tag={tag} updateColor={updateTagColor(index)} />
                </DynamicSizeItem>
              );
            })}
        </div>
      </$ContentWrapper>
    </Modal>
  );
};
