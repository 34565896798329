import { pluralize } from '@aignostics/utils';
import {
  difference as _difference,
  differenceWith as _differenceWith,
} from 'lodash';
import { parse } from 'papaparse';

import {
  Disease,
  SamplePreparation,
  SampleType,
  type Scanner,
  type Staining,
  type Tissue,
} from '../../../types';
import { displayScannerName } from '../../../utils/displayScannerName';
import { overrideHEStaining } from '../Form/form.state';
import {
  DEFAULT_DISEASE_VALUE,
  DEFAULT_SAMPLE_PREPARATION_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../const';

export const MULTIPLE_STAININGS_SEPARATOR = '|';

const REQUIRED_COLUMNS = [
  'filename',
  'tissue',
  'staining',
  'scanner',
  'case id',
  'patientExternalId',
  'block',
  'section',
  'disease',
  'samplePreparation',
  'sampleType',
] as const;

type CSVColumn = (typeof REQUIRED_COLUMNS)[number];
type CSVNullableColumns = Extract<
  CSVColumn,
  | 'tissue'
  | 'staining'
  | 'scanner'
  | 'patientExternalId'
  | 'block'
  | 'section'
  | 'disease'
  | 'samplePreparation'
  | 'sampleType'
>;
type CSVNonNullableColumns = Exclude<CSVColumn, CSVNullableColumns>;
type CSVWSIRow = Record<CSVColumn, string>;
// csv parser results have nullable fields
// csv parser results would have scannerId instead of scannerModel & scannerVendor
type ResultNullableColumns =
  | Exclude<CSVNullableColumns, 'scanner'>
  | 'scannerId';
export type CSVParseResultWSIRow = Record<CSVNonNullableColumns, string> &
  Record<ResultNullableColumns, string | null>;

const getMismatchedColumnsError = ({
  missingColumns,
  extraneousColumns,
}: {
  missingColumns: string[];
  extraneousColumns: string[];
}): string => {
  let msg = 'Mismatching columns in the CSV';
  if (missingColumns.length > 0) {
    msg += `. Missing: ${missingColumns.map((c) => `"${c}"`).join(', ')}`;
  }
  if (extraneousColumns.length > 0) {
    msg += `. Extraneous: ${extraneousColumns.map((c) => `"${c}"`).join(', ')}`;
  }

  return msg;
};

/* eslint-disable-next-line jsdoc/check-line-alignment */
/**
 * Normalizes passed entity to the map with lowercased value keys:
 *
 * Example:
 *
 * ```
 * Map {
 *   'bone marrow' => { id: '14', name: 'Bone Marrow' },
 *   'breast' => { id: '1', name: 'Breast' },
 *   'colon' => { id: '2', name: 'Colon' }
 * }
 * ```
 */
const normalizeEntities = <Entity, Prop extends keyof Entity>(
  entities: Entity[],
  field: Prop
): Map<Entity[Prop], Entity> => {
  const result = new Map<Entity[Prop], Entity>();

  for (const entity of entities) {
    const entityValue = entity[field];
    if (entityValue !== null) {
      const normalizedEntityValue =
        typeof entityValue === 'string'
          ? (entityValue.toLowerCase() as unknown as Entity[Prop])
          : entityValue;
      result.set(normalizedEntityValue, entity);
    }
  }

  return result;
};

const dropFirstLine = async (fileOrText: File | string): Promise<string> => {
  const text =
    typeof fileOrText === 'string' ? fileOrText : await fileOrText.text();
  const newLineIdx = text.indexOf('\n');

  if (newLineIdx !== -1) {
    return text.slice(newLineIdx + 1);
  }

  return '';
};

const isEmptyValue = (string: string) =>
  ['', ' ', null, undefined].includes(string.trim());

type ParserResultSuccess = {
  ok: true;
  data: CSVParseResultWSIRow[];
  csvLineMapping: (number | null)[];
  warnings: string[];
};
type ParserResultFail = {
  ok: false;
  error: string;
};
type ParserResult = ParserResultSuccess | ParserResultFail;

export const csvParser = (
  csvFile: File | string,
  wsis: CSVParseResultWSIRow[],
  tissues: Tissue[],
  stainings: Staining[],
  scanners: Scanner[],
  diseases: Disease[],
  samplePreparations: SamplePreparation[],
  sampleTypes: SampleType[],
  secondAttemptWithStrippedFirstLine = false
): Promise<ParserResult> =>
  new Promise<ParserResult>((resolve) => {
    const CSV_INDEX_OFFSET = secondAttemptWithStrippedFirstLine ? 3 : 2;

    parse<CSVWSIRow>(csvFile, {
      header: true,
      skipEmptyLines: true,
      // eslint-disable-next-line sonarjs/cognitive-complexity
      complete({ data, meta, errors }) {
        // if we had detected only 1 field, that's probably a table header
        if (
          // first field is non-empty
          meta.fields![0] &&
          // rest of the fields are empty
          meta.fields!.slice(1).every((fieldName) => !fieldName) &&
          !secondAttemptWithStrippedFirstLine
        ) {
          void dropFirstLine(csvFile).then((csvTextWithoutFirstLine) => {
            resolve(
              csvParser(
                csvTextWithoutFirstLine,
                wsis,
                tissues,
                stainings,
                scanners,
                diseases,
                samplePreparations,
                sampleTypes,
                true
              )
            );
          });

          return;
        }

        // backwards compatibility for csv files with separate columns for scannerVendor & scannerModel.
        // delete this block, parser.scannerVendorAndScannerModelCompat.spec.ts and fixtures/scannerVendorAndScannerModelCompat
        // when it's not needed anymore
        if (
          meta.fields!.includes('scannerVendor') &&
          meta.fields!.includes('scannerModel') &&
          !meta.fields!.includes('scanner')
        ) {
          meta.fields = meta.fields!.filter(
            (field) => field !== 'scannerVendor' && field !== 'scannerModel'
          );

          meta.fields.push('scanner');

          // replace scannerVendor & scannerModel with unified scanner
          (
            data as (CSVWSIRow &
              Record<'scannerVendor' | 'scannerModel', string>)[]
          ).forEach((datum) => {
            datum.scanner = displayScannerName({
              vendor: datum.scannerVendor,
              model: datum.scannerModel,
            });

            // @ts-expect-error we want to delete those keys
            delete datum.scannerVendor;
            // @ts-expect-error here too
            delete datum.scannerModel;
          });
        }

        if (errors.length > 0) {
          const errorsText = errors.map(({ message }) => message).join('; ');
          resolve({
            ok: false,
            error: `CSV parsing error (malformed input data): ${errorsText}`,
          });

          return;
        }

        const csvColumns = meta.fields ?? [];

        // check columns, hard fail
        const missingColumns = _difference(REQUIRED_COLUMNS, csvColumns);
        const extraneousColumns = _difference(csvColumns, REQUIRED_COLUMNS);
        if (missingColumns.length > 0 || extraneousColumns.length > 0) {
          resolve({
            ok: false,
            error: getMismatchedColumnsError({
              missingColumns,
              extraneousColumns,
            }),
          });

          return;
        }

        const warnings: string[] = [];
        const csvLineMapping: ParserResultSuccess['csvLineMapping'] = [];

        const wsisFilenames = wsis.map((wsi) => wsi.filename);
        const csvFilenames = data.map((datum) => datum.filename);
        const missingsRows = _difference(wsisFilenames, csvFilenames);
        if (missingsRows.length > 0) {
          warnings.push(
            // prettier-ignore
            `Missing ${pluralize('row', missingsRows.length)} in the CSV for files: ${missingsRows
              .sort()
              .join(', ')}`
          );
        }

        const extraneousRows = _differenceWith(
          csvFilenames.map(
            (csvFilename, index) => [csvFilename, index] as [string, number]
          ),
          wsisFilenames,
          ([csvFilename], wsiFilename) => csvFilename === wsiFilename
        );
        if (extraneousRows.length > 0) {
          warnings.push(
            // prettier-ignore
            `Extraneous ${pluralize('row', extraneousRows.length)} in the CSV for ${pluralize('file', extraneousRows.length)}: ${extraneousRows
              .map(([filename]) => filename)
              .sort()
              .join(', ')} (CSV line ${pluralize('number', extraneousRows.length)}: ${extraneousRows
                .map(([, index]) => index + CSV_INDEX_OFFSET)
                .sort((a, b) => a - b)
                .join(', ')})`
          );
        }

        const result: CSVParseResultWSIRow[] = [...wsis];

        const normalizedTissues = normalizeEntities(tissues, 'name');
        const normalizedStainings = normalizeEntities(stainings, 'name');
        const normalizedScanners = normalizeEntities(
          scanners.map((scanner) => ({
            ...scanner,
            displayName: displayScannerName(scanner),
          })),
          'displayName'
        );
        const normalizedDiseases = normalizeEntities(diseases, 'name');
        const normalizedSamplePreparations = normalizeEntities(
          samplePreparations,
          'name'
        );
        const normalizedSampleTypes = normalizeEntities(sampleTypes, 'name');

        const warningsTissuesCsvLines: number[] = [];
        const warningsStainingsCsvLines: number[] = [];
        const warningsWrongCountOfStainingsCsvLines: number[] = [];
        const warningsScannersCsvLines: number[] = [];
        const warningsDiseasesCsvLines: number[] = [];
        const warningsSamplePreparationsCsvLines: number[] = [];
        const warningsSampleTypesCsvLines: number[] = [];

        for (const [wsiIndex, wsi] of wsis.entries()) {
          const csvRowIndex = csvFilenames.indexOf(wsi.filename);
          if (csvRowIndex === -1) {
            csvLineMapping.push(null);
          } else {
            const datum = data[csvRowIndex];
            const isMatchingTissue = normalizedTissues.has(
              datum.tissue.toLowerCase()
            );
            if (!isMatchingTissue) {
              warningsTissuesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            // map he to h&e
            datum.staining =
              overrideHEStaining(stainings, datum.staining) ?? '';
            const csvStainings = datum.staining.split(
              MULTIPLE_STAININGS_SEPARATOR
            );
            const wsiStainings = (wsi.staining ?? '').split(
              MULTIPLE_STAININGS_SEPARATOR
            );
            if (csvStainings.length !== wsiStainings.length) {
              // add warning when count of stainings for multichannel slide
              // file differs from the one we have in the data upload ui
              warningsWrongCountOfStainingsCsvLines.push(
                csvRowIndex + CSV_INDEX_OFFSET
              );
            }
            const isMatchingStainings = csvStainings.every((s) =>
              normalizedStainings.has(s.toLowerCase())
            );
            if (!isMatchingStainings) {
              warningsStainingsCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            // only check for mismatch if value is not empty, if it is empty shouldn't show a warning
            const isMatchingDiseaseFound = !isEmptyValue(datum.disease)
              ? normalizedDiseases.has(datum.disease.toLowerCase())
              : true;
            if (!isMatchingDiseaseFound) {
              warningsDiseasesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingSamplePreparation = !isEmptyValue(
              datum.samplePreparation
            )
              ? normalizedSamplePreparations.has(
                  datum.samplePreparation.toLowerCase()
                )
              : true;
            if (!isMatchingSamplePreparation) {
              warningsSamplePreparationsCsvLines.push(
                csvRowIndex + CSV_INDEX_OFFSET
              );
            }

            const isMatchingSampleType = !isEmptyValue(datum.sampleType)
              ? normalizedSampleTypes.has(datum.sampleType.toLowerCase())
              : true;
            if (!isMatchingSampleType) {
              warningsSampleTypesCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            const isMatchingScanner = normalizedScanners.has(
              datum.scanner.toLowerCase()
            );

            if (!isMatchingScanner) {
              warningsScannersCsvLines.push(csvRowIndex + CSV_INDEX_OFFSET);
            }

            csvLineMapping.push(csvRowIndex + CSV_INDEX_OFFSET);

            result[wsiIndex] = {
              filename: datum.filename,
              tissue: isMatchingTissue
                ? normalizedTissues.get(datum.tissue.toLowerCase())!.name
                : null,
              staining:
                wsiStainings.length > 1
                  ? datum.staining
                  : isMatchingStainings
                    ? normalizedStainings.get(datum.staining.toLowerCase())!
                        .name
                    : null,
              scannerId: isMatchingScanner
                ? normalizedScanners.get(datum.scanner.toLowerCase())!.id
                : null,
              'case id':
                datum['case id'] && datum['case id'].length > 0
                  ? datum['case id']
                  : datum.filename.slice(0, datum.filename.lastIndexOf('.')),
              patientExternalId: datum.patientExternalId,
              block: datum.block,
              section: datum.section,
              disease: !isEmptyValue(datum.disease)
                ? datum.disease
                : DEFAULT_DISEASE_VALUE,
              samplePreparation: !isEmptyValue(datum.samplePreparation)
                ? datum.samplePreparation
                : DEFAULT_SAMPLE_PREPARATION_VALUE,
              sampleType: !isEmptyValue(datum.sampleType)
                ? datum.sampleType
                : DEFAULT_SAMPLE_TYPE_VALUE,
            };
          }
        }

        (
          [
            ['tissues', warningsTissuesCsvLines],
            ['stainings', warningsStainingsCsvLines],
            ['scanners', warningsScannersCsvLines],
            ['diseases', warningsDiseasesCsvLines],
            ['samplePreparations', warningsSamplePreparationsCsvLines],
            ['sampleTypes', warningsSampleTypesCsvLines],
          ] as const
        ).forEach(([entity, warningsCsvLines]) => {
          if (warningsCsvLines.length > 0) {
            warnings.push(
              `Bad ${pluralize(
                'value',
                warningsCsvLines.length
              )} for ${entity} on CSV ${pluralize(
                'line',
                warningsCsvLines.length
              )} ${warningsCsvLines.join(', ')}`
            );
          }
        });

        if (warningsWrongCountOfStainingsCsvLines.length > 0) {
          warnings.push(
            `Mismatching count of channels for multichannel slide file on CSV ${pluralize(
              'line',
              warningsWrongCountOfStainingsCsvLines.length
            )} ${warningsWrongCountOfStainingsCsvLines.join(', ')}`
          );
        }

        resolve({
          ok: true,
          data: result,
          csvLineMapping,
          warnings,
        });
      },
      error(error) {
        resolve({
          ok: false,
          error: `CSV parsing error: ${error.message}`,
        });
      },
    });
  });
