import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $RadioButton = styled(motion.div) `
  display: grid;
  place-content: center;
  height: 16px;
  width: 16px;
  padding: 10px;
  border-radius: 50%;

  &:focus-within {
    outline: solid 2px ${({ theme }) => theme.colors.primary};
  }

  input[type='radio'] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0;
    width: 16px;
    height: 16px;
    outline: 1px solid ${({ theme }) => theme.colors.dark};
    border-radius: 50%;
    &:disabled {
      opacity: 0.5;
    }
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      outline: solid 5px ${({ theme }) => theme.colors.primary};
      transition: 120ms transform ease-in-out;
      transform: scale(0);
    }
    &:checked::before {
      transform: scale(1);
    }
  }
`;
