import styled, { css, keyframes } from 'styled-components';
const CIRCLE_SIZE = 24;
const STROKE_WIDTH = 1.5;
const CIRCLE_CENTER = CIRCLE_SIZE / 2;
const CIRCLE_RADIUS = CIRCLE_SIZE / 2 - STROKE_WIDTH;
const CIRCLE_ARC_LENGTH = 2 * Math.PI * CIRCLE_RADIUS;
const rotatingKeyFrames = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotatingAnimation = css `
  animation: ${rotatingKeyFrames} 1s linear infinite;
`;
export const $CircularProgress = styled.svg `
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  transform: rotate(-90deg) scaleY(-1);
  ${({ indeterminate }) => (indeterminate ? rotatingAnimation : '')}
`;
export const $CircularProgressTrack = styled.circle `
  cx: ${CIRCLE_CENTER}px;
  cy: ${CIRCLE_CENTER}px;
  r: ${CIRCLE_RADIUS}px;
  fill: transparent;
  stroke-width: ${STROKE_WIDTH}px;
  stroke: #ddd;
`;
const MAX_LENGTH = 0.85;
const MIN_LENGTH = 0.55;
const strokeDashOffsetKeyframes = keyframes `
  0% {
    stroke-dashoffset: ${CIRCLE_ARC_LENGTH * MAX_LENGTH}
  }
  50% {
    stroke-dashoffset:  ${CIRCLE_ARC_LENGTH * MIN_LENGTH}
  }
  100% {
    stroke-dashoffset:  ${CIRCLE_ARC_LENGTH * MAX_LENGTH}
  }
`;
const strokeDashOffsetAnimation = css `
  animation: ${strokeDashOffsetKeyframes} 1s linear infinite;
`;
export const $CircularProgressIndicator = styled.circle `
  cx: ${CIRCLE_CENTER}px;
  cy: ${CIRCLE_CENTER}px;
  r: ${CIRCLE_RADIUS}px;
  fill: transparent;
  stroke-width: ${STROKE_WIDTH}px;
  stroke: #07c;
  stroke-dasharray: ${CIRCLE_ARC_LENGTH};
  stroke-dashoffset: ${({ progress }) => progress === -1
    ? CIRCLE_ARC_LENGTH * 0.75
    : CIRCLE_ARC_LENGTH * ((100 - (progress > 100 ? 100 : progress)) / 100)};
  ${({ progress }) => (progress === -1 ? strokeDashOffsetAnimation : '')}
`;
