import { downloadCSV } from './downloadCsv';

/**
 * Generates a csv file with relevant metadata locally and triggers download.
 */

export const downloadDataOnboardingTemplate = (): void => {
  const header: string[] = [
    'filename',
    'tissue',
    'staining',
    'scanner',
    'case id',
    'patientExternalId',
    'section',
    'block',
    'disease',
    'samplePreparation',
    'sampleType',
  ];

  const DEFAULT_DATA_ONBOARDING_TEMPLATE_CONTENT = [
    [
      '01.tif',
      'colon',
      'ae1',
      'ventana',
      '00000000-0000-0000-0000-000000000001',
      'patient1',
      'section1',
      'block1',
      'NSCLC',
      'FFPE',
      'Resection',
    ],
    [
      '02.tif',
      'colon',
      'ae1',
      'ventana',
      '00000000-0000-0000-0000-000000000002',
      'patient2',
      'section2',
      'block2',
      'NSCLC',
      'FFPE',
      'Resection',
    ],
    [
      '03.tif',
      'colon',
      'ae1',
      'ventana',
      '00000000-0000-0000-0000-000000000003',
      'patient3',
      'section3',
      'block3',
      'NSCLC',
      'FFPE',
      'Resection',
    ],
    [
      '04.tif',
      'colon',
      'ae1|ae2|dapi',
      'ventana',
      '00000000-0000-0000-0000-000000000004',
      'patient4',
      'section4',
      'block4',
      'NSCLC',
      'FFPE',
      'Resection',
    ],
  ];

  downloadCSV(
    [header, ...DEFAULT_DATA_ONBOARDING_TEMPLATE_CONTENT],
    'data-onboarding-template-example'
  );
};
