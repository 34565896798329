import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrushTool } from './DrawingMode';

type BrushSettings = Pick<BrushTool, 'size'>;

type SliderInteraction = 'idle' | 'resizing';

interface DrawingToolSettingsStore {
  annotation: {
    brush: BrushSettings;
  };
  latestRegionSize: number;
  setBrushSize: (brushSize: number) => void;
  setLatestRegionSize: (size: number) => void;
  sliderInteraction: SliderInteraction;
  setSliderInteraction: (interaction: SliderInteraction) => void;
}

export const useDrawingToolSettingsStore = create(
  persist<DrawingToolSettingsStore>(
    (set) => ({
      annotation: {
        brush: {
          size: 10,
        },
      },
      latestRegionSize: 100,
      setBrushSize: (brushSize) => {
        set({ annotation: { brush: { size: brushSize } } });
      },
      setLatestRegionSize: (size) => {
        set({ latestRegionSize: size });
      },
      sliderInteraction: 'idle',
      setSliderInteraction: (interaction) => {
        set({ sliderInteraction: interaction });
      },
    }),
    {
      name: 'drawing-tool',
    }
  )
);
