import styled from 'styled-components';

export const $Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.large}px;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.large}px;
`;

export const $DrawingTool = styled.div`
  background-color: ${({ theme }) => theme.colors.lighter};
  padding: ${({ theme }) => `${theme.spacings.large}px`};
`;

export const $FooterWrapper = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 24px 0px #0000001a;
  width: 100%;
  justify-content: center;
  z-index: 1;
`;

export const $Footer = styled.div`
  max-width: ${({ theme }) => `${theme.breakpoints.FULL}px`};
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[16]}px`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const $AnnotationsFeatureSelectContainer = styled.div`
  gap: ${({ theme }) => `${2 * theme.spacings.large}px`};
  max-width: ${({ theme }) => `${theme.breakpoints.FULL}px`};
`;

export const $AnnotationsFormUnsavedChangesWarning = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;
