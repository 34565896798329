import {
  Button,
  CheckboxWithLegend,
  HStack,
  Loader,
  RadioButtonWithLegend,
  Section,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { SortByDirection, useSortBy } from '../../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategoryChange,
  AnnotationDrawingTools,
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../../types';
import { getQueryParams } from '../../../../utils';
import { AnnotationCategoryInput } from '../AdminSubProjectCreateAnnotationCategory/Admin.SubProject.CreateAnnotationCategoryModal.component';
import {
  $AnnotationsFeatureSelectContainer,
  $AnnotationsFormUnsavedChangesWarning,
  $Card,
  $DrawingTool,
  $Footer,
  $FooterWrapper,
} from './AdminSubprojectAnnotations.styles';
import { AdminSubprojectCategories } from './AdminSubprojectCategories';
import { FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS } from './AdminSubprojectCategories/FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS.queries';
import { getUpdatedAnnotationCategories } from './AdminSubprojectCategories/utils';
import { useUpdateSubProjectAnnotations } from './useUpdateSubProjectAnnotations';
import { getAddedOrRemovedAnnotationCategories } from './utils';

export const LAST_ADMIN_SUBPROJECT_ANNOTATION_SECTION_PADDING_BOTTOM = '96px';

interface AdminSubprojectAnnotationsForm {
  annotationFeature: AnnotationFeatureType;
  otherAnnotationVisibility: OtherAnnotationVisibility;
  annotationDrawingTools: AnnotationDrawingTools;
  annotationCategories: AnnotationCategory[];
}

const AdminSubprojectAnnotations = (): ReactElement => {
  const theme = useTheme();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as AnnotationCategoriesSortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

  const { sortBy, setSortByOptions } =
    useSortBy<AnnotationCategoriesSortByOptions>({
      column: sortByParam || 'name',
      sortDirection: sortDirectionParam || 'asc',
    });

  const { projectId, subProjectId } = useParams<{
    projectId: string;
    subProjectId: string;
  }>();

  const { addSnackbar } = useSnackbarMutations();

  const {
    data: subprojectAnnotationsSettings,
    loading: subprojectAnnotationsSettingsLoading,
    error: subprojectAnnotationsSettingsError,
  } = useQuery<{
    annotationCategories: AnnotationCategory[];
    subProject: {
      annotationDrawingTools: AnnotationDrawingTools;
      annotationFeature: AnnotationFeatureType;
      otherAnnotationVisibility: OtherAnnotationVisibility;
    };
  }>(FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS, {
    variables: { subProjectId },
  });

  const {
    updateSubProjectAnnotations,
    errors,
    loading: updateSubProjectAnnotationsLoading,
  } = useUpdateSubProjectAnnotations({
    onUpdateCompleted: () => {
      setNewAnnotationCategories([]);
      setIsSubmitted(true);
    },
  });

  const [newAnnotationCategories, setNewAnnotationCategories] = useState<
    AnnotationCategoryInput[]
  >([]);

  const initialValues = useMemo(() => {
    if (
      subprojectAnnotationsSettingsLoading ||
      subprojectAnnotationsSettingsError ||
      !subprojectAnnotationsSettings
    ) {
      return null;
    }
    const {
      subProject: {
        annotationDrawingTools: { pen, brush, picker },
        annotationFeature,
        otherAnnotationVisibility,
      },
      annotationCategories,
    } = subprojectAnnotationsSettings;
    return {
      annotationFeature: annotationFeature ?? 'OFF',
      otherAnnotationVisibility: otherAnnotationVisibility ?? 'OFF',
      annotationDrawingTools: {
        pen,
        picker,
        brush,
      },
      annotationCategories: annotationCategories ?? [],
    };
  }, [
    subprojectAnnotationsSettings,
    subprojectAnnotationsSettingsLoading,
    subprojectAnnotationsSettingsError,
  ]);

  const handleSubmit = (formValues: AdminSubprojectAnnotationsForm) => {
    if (initialValues) {
      setIsSubmitted(true);

      const {
        annotationFeature,
        otherAnnotationVisibility,
        annotationDrawingTools,
        annotationCategories,
      } = formValues;

      const addedAnnotationCategories = getAddedOrRemovedAnnotationCategories(
        subProjectId as string,
        initialValues.annotationCategories,
        annotationCategories,
        'added'
      );

      const removedAnnotationCategories = getAddedOrRemovedAnnotationCategories(
        subProjectId as string,
        initialValues.annotationCategories,
        annotationCategories,
        'removed'
      );

      const updatedSubproject = {
        id: subProjectId as string,
        projectId: projectId as string,
        annotationFeature,
        otherAnnotationVisibility,
        annotationDrawingToolsInput: annotationDrawingTools,
      };

      updateSubProjectAnnotations({
        updatedSubProject: updatedSubproject,
        addedAnnotationCategories,
        removedAnnotationCategories,
        newAnnotationCategories: newAnnotationCategories.map(
          ({
            __typename,
            id,
            color,
            inParent,
            name,
            annotationCategorySet,
          }) => {
            if (annotationCategorySet?.kind === 'unset') {
              return {
                __typename,
                id,
                color,
                name,
                inParent,
              };
            } else {
              return {
                __typename,
                id,
                color,
                name,
                inParent,
                annotationCategorySet,
              };
            }
          }
        ),
      });
    }
  };

  const loading =
    updateSubProjectAnnotationsLoading || subprojectAnnotationsSettingsLoading;

  const error =
    subprojectAnnotationsSettingsError ||
    errors.subProject ||
    errors.createAnnotationCategories;

  return (
    <Section
      title="Configure Annotations"
      description="Define who can create, view, edit and delete annotations."
      loading={loading}
      error={error}
      style={{
        paddingBottom:
          subprojectAnnotationsSettings?.subProject.annotationFeature !== 'ON'
            ? LAST_ADMIN_SUBPROJECT_ANNOTATION_SECTION_PADDING_BOTTOM
            : '',
      }}
    >
      {subprojectAnnotationsSettingsLoading && <Loader />}
      {!subprojectAnnotationsSettingsLoading && initialValues && (
        <Form<AdminSubprojectAnnotationsForm, AdminSubprojectAnnotationsForm>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          // this property is needed to prevet visual "flickering" to initial values upon submit
          keepDirtyOnReinitialize
          render={(formRenderProps) => {
            const { handleSubmit, form, dirtySinceLastSubmit, dirty } =
              formRenderProps;

            const { getState, reset, setConfig } = form;
            const { values } = getState();
            const {
              annotationFeature,
              otherAnnotationVisibility,
              annotationDrawingTools,
              annotationCategories,
            } = values;
            const { pen, brush, picker } = annotationDrawingTools;

            // dirty will always be false after isSubmitted is true
            // dirtySinceLastSubmit will always be false until isSubmitted is true
            // we have to use dirty before first submit, and dirtySinceLastSubmit afterwards
            const inputsChanged = !isSubmitted ? dirty : dirtySinceLastSubmit;
            const isAnnotationFeatureOnAndDrawingToolSelected =
              annotationFeature === 'ON' && (pen || brush || picker);

            const canSubmit =
              inputsChanged &&
              (isAnnotationFeatureOnAndDrawingToolSelected ||
                annotationFeature !== 'ON');

            return (
              <>
                <$AnnotationsFeatureSelectContainer>
                  <Field
                    name="annotationFeature"
                    render={({
                      input: { onChange: onAnnotationFeatureChange },
                    }) => (
                      <VStack spacing="base" style={{ width: '100%' }}>
                        <$Card
                          role="group"
                          aria-label="Disabled Annotations"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'OFF' as AnnotationFeatureType}
                            id="annotation-disabled"
                            label="Disabled"
                            legend="It disables the feature for viewers on this subproject: annotations can't be created, viewed nor edited."
                            checked={annotationFeature === 'OFF'}
                            onChange={() => {
                              onAnnotationFeatureChange('OFF');
                            }}
                          />
                        </$Card>
                        <$Card
                          role="group"
                          aria-label="Annotations read"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'READ' as AnnotationFeatureType}
                            id="annotations-read"
                            label="Read-Only"
                            legend="User can only view own existing annotations. No editing allowed."
                            checked={annotationFeature === 'READ'}
                            onChange={() => {
                              onAnnotationFeatureChange('READ');
                            }}
                          />
                          <Field
                            name="otherAnnotationVisibility"
                            render={({
                              input: {
                                onChange: onOtherAnnotationVisibilityChange,
                              },
                            }) => (
                              <$Card
                                style={{
                                  backgroundColor: theme.colors.lighter,
                                }}
                              >
                                <CheckboxWithLegend
                                  name="annotations-read-others-visibility"
                                  id="annotations-read-others-visibility"
                                  label="User can also view others annotations"
                                  legend="It allows user to also view, without editing, annotations from other users."
                                  checked={
                                    ['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                      otherAnnotationVisibility
                                    ) && annotationFeature === 'READ'
                                  }
                                  onChange={(checked) => {
                                    onOtherAnnotationVisibilityChange(
                                      checked ? 'READ_ONLY' : 'OFF'
                                    );
                                  }}
                                  disabled={annotationFeature !== 'READ'}
                                />
                                {['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                  otherAnnotationVisibility
                                ) &&
                                  annotationFeature === 'READ' && (
                                    <CheckboxWithLegend
                                      name="annotations-edit-others-visibility"
                                      id="annotations-edit-others-visibility-anonymous"
                                      label="Anonymize annotators"
                                      legend="It will anonymize other anotators' names"
                                      checked={
                                        otherAnnotationVisibility ===
                                          'READ_ANONYMOUS' &&
                                        annotationFeature === 'READ'
                                      }
                                      onChange={(checked) => {
                                        onOtherAnnotationVisibilityChange(
                                          checked
                                            ? 'READ_ANONYMOUS'
                                            : 'READ_ONLY'
                                        );
                                      }}
                                      disabled={annotationFeature !== 'READ'}
                                    />
                                  )}
                              </$Card>
                            )}
                          />
                        </$Card>
                        <$Card
                          role="group"
                          aria-label="Annotations edit"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'ON' as AnnotationFeatureType}
                            id="annotations-edit"
                            label="Create, Edit & Delete"
                            legend="User can create, view, edit and delete own annotations on this subproject."
                            checked={annotationFeature === 'ON'}
                            onChange={() => {
                              onAnnotationFeatureChange('ON');
                            }}
                          />
                          <Field
                            name="otherAnnotationVisibility"
                            render={({
                              input: {
                                onChange: onOtherAnnotationVisibilityChange,
                              },
                            }) => (
                              <$Card
                                style={{
                                  backgroundColor: theme.colors.lighter,
                                }}
                              >
                                <CheckboxWithLegend
                                  name="annotations-edit-others-visibility"
                                  id="annotations-edit-others-visibility"
                                  label="User can also view others annotations"
                                  legend="It allows user to also view, without editing, annotations from other users."
                                  checked={
                                    ['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                      otherAnnotationVisibility
                                    ) && annotationFeature === 'ON'
                                  }
                                  onChange={(checked) => {
                                    onOtherAnnotationVisibilityChange(
                                      checked ? 'READ_ONLY' : 'OFF'
                                    );
                                  }}
                                  disabled={annotationFeature !== 'ON'}
                                />
                                {['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                  otherAnnotationVisibility
                                ) &&
                                  annotationFeature === 'ON' && (
                                    <CheckboxWithLegend
                                      name="annotations-edit-others-visibility"
                                      id="annotations-edit-others-visibility-anonymous"
                                      label="Anonymize annotators"
                                      legend="It will anonymize other anotators' names"
                                      checked={
                                        otherAnnotationVisibility ===
                                          'READ_ANONYMOUS' &&
                                        annotationFeature === 'ON'
                                      }
                                      onChange={(checked) => {
                                        onOtherAnnotationVisibilityChange(
                                          checked
                                            ? 'READ_ANONYMOUS'
                                            : 'READ_ONLY'
                                        );
                                      }}
                                      disabled={annotationFeature !== 'ON'}
                                    />
                                  )}
                              </$Card>
                            )}
                          />

                          <p style={theme.fontStyles.smallBold}>
                            Configure the drawing options that can be used to
                            annotate:
                          </p>
                          <HStack
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              width: '100%',
                              backgroundColor: theme.colors.white,
                            }}
                            data-testid="drawing-tool-checkbox"
                          >
                            <Field
                              name="annotationDrawingTools.pen"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <CheckboxWithLegend
                                    label="Pen tool"
                                    name="Pen Tool"
                                    legend="Draw precise outlines on slides."
                                    id="penTool"
                                    icon="Pen"
                                    onChange={onAnnotationDrawingToolsChange}
                                    checked={annotationDrawingTools.pen}
                                    disabled={annotationFeature !== 'ON'}
                                  />
                                </$DrawingTool>
                              )}
                            />

                            <Field
                              name="annotationDrawingTools.brush"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <CheckboxWithLegend
                                    label="Brush tool"
                                    name="Brush tool"
                                    id="brushTool"
                                    legend="Quickly mark regions on slides."
                                    icon="Brush"
                                    onChange={onAnnotationDrawingToolsChange}
                                    checked={annotationDrawingTools.brush}
                                    disabled={annotationFeature !== 'ON'}
                                  />
                                </$DrawingTool>
                              )}
                            />

                            <Field
                              name="annotationDrawingTools.picker"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <CheckboxWithLegend
                                    label="Picker tool"
                                    name="Picker tool"
                                    id="pickerTool"
                                    legend="Pick cells of interactive overlays."
                                    icon="Crosshair"
                                    onChange={onAnnotationDrawingToolsChange}
                                    checked={annotationDrawingTools.picker}
                                    disabled={annotationFeature !== 'ON'}
                                  />
                                </$DrawingTool>
                              )}
                            />
                          </HStack>
                        </$Card>
                      </VStack>
                    )}
                  />
                </$AnnotationsFeatureSelectContainer>
                <Field
                  name="annotationCategories"
                  render={({
                    input: { onChange: onAnnotationCategoriesChange },
                  }) =>
                    annotationFeature === 'ON' && (
                      <AdminSubprojectCategories
                        annotationCategories={annotationCategories}
                        sortBy={sortBy}
                        setSortByOptions={setSortByOptions}
                        onUpdateAnnotationCategories={(
                          args: AnnotationCategoryChange[]
                        ) => {
                          onAnnotationCategoriesChange(
                            getUpdatedAnnotationCategories({
                              categoryChanges: args,
                              annotationCategories,
                            })
                          );
                        }}
                        onImportAnnotationCategoriesFromSubProject={(
                          importedCategories
                        ) => {
                          const updatedAnnotationCategories =
                            annotationCategories.map((annotationCategory) => {
                              const importedCategory = importedCategories.find(
                                (c) => c.id === annotationCategory.id
                              );
                              return {
                                ...annotationCategory,
                                inParent: importedCategory
                                  ? true
                                  : annotationCategory.inParent,
                                color: importedCategory
                                  ? importedCategory.color
                                  : annotationCategory.color,
                              };
                            });
                          onAnnotationCategoriesChange(
                            updatedAnnotationCategories
                          );
                        }}
                        onCreateAnnotationCategory={(newCategory) => {
                          setNewAnnotationCategories([
                            ...newAnnotationCategories,
                            newCategory,
                          ]);
                          onAnnotationCategoriesChange([
                            ...annotationCategories,
                            newCategory,
                          ]);
                        }}
                      />
                    )
                  }
                />
                <$FooterWrapper>
                  <$Footer>
                    <HStack
                      spacing="large"
                      justifyContent="start"
                      alignItems="center"
                    >
                      {canSubmit && (
                        <$AnnotationsFormUnsavedChangesWarning>
                          You have unsaved changes. if you leave without saving,
                          they will be lost.
                        </$AnnotationsFormUnsavedChangesWarning>
                      )}
                    </HStack>
                    <HStack spacing="large" justifyContent="end">
                      <Button
                        variant="primaryOutline"
                        onClick={() => {
                          setConfig('keepDirtyOnReinitialize', false);
                          reset();
                          setConfig('keepDirtyOnReinitialize', true);
                          addSnackbar({
                            type: 'info',
                            message: 'The configuration has been reset',
                            closesAfter: 1000,
                          });
                        }}
                        disabled={loading || !canSubmit}
                      >
                        Reset changes
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        disabled={loading || !canSubmit}
                      >
                        Save changes
                      </Button>
                    </HStack>
                  </$Footer>
                </$FooterWrapper>
              </>
            );
          }}
        />
      )}
    </Section>
  );
};
export default AdminSubprojectAnnotations;
