import {
  Button,
  ColorInput,
  FieldValue,
  HStack,
  Input,
  Modal,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AnnotationCategory, AnnotationCategorySet } from '../../../../types';
import { AnnotationCategoryFormSetBlock } from './Admin.SubProject.CreateAnnotationCategoryModal.CategorySetBlock';
import {
  $CreateAnnotationCategoryColorWrapper,
  $CreateAnnotationCategoryForm,
  $CreateAnnotationCategoryNameWrapper,
} from './Admin.SubProject.CreateAnnotationCategoryModal.component.styles';

export const HEXADECIMAL_PATTERN = /^#[0-9A-F]{6}$/i;

type AnnotationCategorySetModuleAssign = {
  kind: 'assign';
  moduleId: string;
  moduleName: string;
};

type AnnotationCategorySetModuleCreate = {
  kind: 'create';
  moduleId: string;
  moduleName: string;
};

export type AnnotationCategorySetModuleInput =
  | AnnotationCategorySetModuleAssign
  | AnnotationCategorySetModuleCreate;

type AnnotationCategorySetCreate = {
  id: string;
  kind: 'create';
  name: string;
  code: string | null;
  module?: AnnotationCategorySetModuleCreate;
};

type AnnotationCategorySetAssign = {
  kind: 'assign';
  id: string;
  categorySet: AnnotationCategorySet;
  module?: AnnotationCategorySetModuleInput;
};

type AnnotationCategorySetUnset = {
  kind: 'unset';
};

export type AnnotationCategorySetInput =
  | AnnotationCategorySetAssign
  | AnnotationCategorySetCreate
  | AnnotationCategorySetUnset;

export type AnnotationCategoryInput = AnnotationCategory & {
  annotationCategorySet?: AnnotationCategorySetInput;
};

export const isNewAnnotationCategorySetCodeValid = (
  newCode: string,
  annotationCategorySets: AnnotationCategorySet[] | undefined
): boolean => {
  if (!newCode || !annotationCategorySets) return false;
  return !annotationCategorySets.find((set) => set.code === newCode);
};

const formatAnnotationCategorySetInputValue = (
  set: AnnotationCategorySetInput
) => {
  switch (set.kind) {
    case 'unset': {
      return {};
    }
    case 'create': {
      const { name, code, module } = set;
      return {
        setName: name,
        setCode: code,
        setModule: module?.moduleName,
        annotationCategorySet: set,
      };
    }
    case 'assign': {
      const { kind, id, module, categorySet } = set;
      const { name, code } = categorySet;

      return {
        setName: name,
        setCode: code,
        setModule: module?.moduleName,
        annotationCategorySet: {
          kind,
          id,
          module,
        },
      };
    }
  }
};

const AdminSubProjectCreateAnnotationCategoryModal = ({
  isVisible,
  onClose,
  onCreateAnnotationCategory,
  annotationCategorySets,
}: {
  isVisible: boolean;
  onClose: () => void;
  onCreateAnnotationCategory: (
    newAnnotationCategory: AnnotationCategoryInput
  ) => void;
  annotationCategorySets: AnnotationCategorySet[];
}): ReactElement => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('');

  const [annotationCategorySetInput, setAnnotationCategorySetInput] =
    useState<AnnotationCategorySetInput>({ kind: 'unset' });

  const { addSnackbar } = useSnackbarMutations();

  const handleCreateCategory = (
    values: Record<string, FieldValue | null | AnnotationCategorySetInput>
  ) => {
    if (!HEXADECIMAL_PATTERN.test(color)) {
      addSnackbar({
        type: 'error',
        message: `${color} is not a valid color! please use a valid hexadecimal value.`,
      });
      return;
    }

    onCreateAnnotationCategory({
      ...values,
      ...formatAnnotationCategorySetInputValue(annotationCategorySetInput),
      id: uuid(),
    } as AnnotationCategoryInput);
    /** Clear Form input after submit to have any user input */
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setColor('');
    setAnnotationCategorySetInput({ kind: 'unset' });
    onClose();
  };

  const canSubmitAnnotationCategorySet =
    annotationCategorySetInput.kind === 'create'
      ? annotationCategorySetInput.name &&
        annotationCategorySetInput.code &&
        isNewAnnotationCategorySetCodeValid(
          annotationCategorySetInput.code,
          annotationCategorySets
        )
      : true;

  const canSubmit =
    name &&
    color &&
    (annotationCategorySetInput.kind === 'unset' ||
      canSubmitAnnotationCategorySet);

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      shouldCloseOnEscKey
      hasCloseButton
    >
      <$CreateAnnotationCategoryForm title="Create Annotation Category">
        <HStack as="form" spacing="8">
          <$CreateAnnotationCategoryNameWrapper>
            <Input
              id="create-annotation-category-name-input-id"
              type="text"
              onChange={(value) => {
                setName(value);
              }}
              value={name}
              label="Name"
              required={true}
            />
          </$CreateAnnotationCategoryNameWrapper>
          <$CreateAnnotationCategoryColorWrapper>
            <ColorInput
              label="Color"
              id="create-annotation-category-color-input-id"
              onChange={(value) => {
                setColor(value);
              }}
              value={color}
              required={true}
              isInline={true}
            />
          </$CreateAnnotationCategoryColorWrapper>
        </HStack>
        <AnnotationCategoryFormSetBlock
          annotationCategorySetInput={annotationCategorySetInput}
          annotationCategorySets={annotationCategorySets}
          updateAnnotationCategorySetInput={setAnnotationCategorySetInput}
        />
        <VStack>
          <Button
            id="create-annotation-category-submit-button-id"
            onClick={(e) => {
              e.preventDefault();
              handleCreateCategory({
                name,
                color,
                annotationCategorySet: annotationCategorySetInput,
              });
            }}
            small
            type="submit"
            disabled={!canSubmit}
          >
            Submit
          </Button>
        </VStack>
      </$CreateAnnotationCategoryForm>
    </Modal>
  );
};

export default AdminSubProjectCreateAnnotationCategoryModal;
