import { useSnackbarMutations } from '@aignostics/components';
import { User } from '@aignostics/core';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { SubProject } from '../../../../types';
import { cacheCreateSubProject } from '../../../../utils';
import { CreateSubProjectFormValues } from './Admin.SubProject.Create';
import { CREATE_SUB_PROJECT } from './CREATE_SUB_PROJECT.queries';

type CreateSubProject = (values: CreateSubProjectFormValues) => Promise<void>;

export const useCreateSubproject = (
  projectId: string,
  onSubProjectCreation: (subProjectId: string) => void,
  currentUser: User
): {
  createSubProject: CreateSubProject;
  loading: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const [createSubProjectRequest, { loading: createSubProjectLoading }] =
    useMutation(CREATE_SUB_PROJECT);

  const createSubProject: CreateSubProject = async (values) => {
    const subProject: Partial<SubProject> = {
      ...values,
      projectId,
    };

    try {
      const {
        data: { createSubProject: subProjectId },
      } = await createSubProjectRequest({
        variables: subProject,
        update: cacheCreateSubProject(subProject, currentUser),
      });

      addSnackbar({
        type: 'success',
        message: 'Subproject was successfully created',
      });

      onSubProjectCreation(subProjectId);
    } catch (error) {
      Sentry.captureException(error);
      addSnackbar({
        type: 'error',
        message: 'Error creating subproject',
      });
    }
  };

  return {
    createSubProject,
    loading: createSubProjectLoading,
  };
};
