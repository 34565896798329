import { Annotation } from '../../../../api-types';

export const getPrevIndex = (
  annotations: Annotation[],
  index: number | null
): number => {
  if (index === 0 || index === null) {
    return annotations.length - 1;
  }

  return index - 1;
};

export const getNextIndex = (
  annotations: Annotation[],
  index: number | null
): number => {
  if (index === null || index === annotations.length - 1) {
    return 0;
  }
  if (index > annotations.length) {
    return 0;
  }

  return index + 1;
};
