import { SubProject } from '../types';
import { CsvRow, downloadCSV } from './downloadCsv';

/** Uppercase first char of the passed string */
const capitalizeWord = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Generates a file with relevant metadata locally and triggers download.
 * @param subProject SubProject entity for report
 */
const downloadReport = (subProject: SubProject | null): void => {
  if (subProject) {
    /** Header row */
    const header: string[] = ['name', 'grade'].map((val) =>
      capitalizeWord(val)
    );

    /** Content rows */
    const content = subProject.wsis.nodes.map(({ name }) => [name]);

    /** All rows for csv */
    const rows: CsvRow[] = [header, ...content];

    downloadCSV(rows, subProject.name);
  }
};

export default downloadReport;
