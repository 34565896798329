import { contrast } from '@aignostics/utils';
import styled from 'styled-components';
export const $HeaderImage = styled.img `
  position: absolute;
  top: 0%;
  right: 0%;
  width: 75%;

  @media (min-width: ${({ theme }) => theme.breakpoints.FULL}px) {
    right: auto;
    left: 40%;
    max-width: ${({ theme }) => theme.breakpoints.TRIPLE}px;
  }
`;
export const $PageHeaderWrapper = styled.section `
  position: relative;
  width: 100%;
  background-color: ${({ background, theme }) => theme.colors[background]};
  color: ${({ background, theme }) => contrast(theme.colors[background], theme)};
  padding: 56px;

  a {
    &:visited {
      color: ${({ theme }) => theme.colors.light};
    }
  }
`;
export const $PageHeader = styled.div `
  margin: auto;
  ${({ theme, fullWidth }) => {
    if (!fullWidth) {
        return `max-width: ${theme.breakpoints.FULL}px;`;
    }
    return '';
}}
`;
export const $PageHeaderContainer = styled.div `
  width: ${({ theme }) => theme.breakpoints.PAGE_HEADER_CONTAINER}px;
`;
export const $PageTitle = styled.h1 `
  overflow: hidden;
  ${({ theme }) => theme.fontStyles.subtitleBold};
  min-height: ${({ theme }) => `${theme.spacings[48]}px`};
  min-width: ${({ theme }) => `${theme.spacings[2]}px`};

  @media (min-width: ${({ theme }) => theme.breakpoints.TRIPLE}px) {
    ${({ theme }) => theme.fontStyles.subtitleBold};
  }
`;
export const $DescriptionFragment = styled.p `
  ${({ theme }) => theme.fontStyles.base};
  margin-top: ${({ theme }) => `${theme.spacings[16]}px`};
  color: ${({ background, theme }) => contrast(theme.colors[background], theme)};
  margin-bottom: ${({ theme }) => theme.spacings.large}px;

  a {
    color: ${({ background, theme }) => contrast(theme.colors[background], theme)};
  }
`;
export const $ShowMoreButton = styled.div `
  text-decoration: underline;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontStyles.tiny}px;
`;
