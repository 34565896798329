import { AdminSubprojectAlgorithms } from '@aignostics/algorithms-ui';
import { PageLayout, SubNavigation } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { FunctionComponent, ReactElement } from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';
import { useHandleSubprojectQueryErrors } from '../../../hooks/useHandleQueryErrors';
import { SubProject } from '../../../types';
import { FETCH_ADMIN_SUB_PROJECT_BASE_DETAILS } from '../../Projects/Admin/FETCH_ADMIN_SUB_PROJECT_DETAILS';
import AdminSubprojectAssignInteractiveOverlays from './AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.component';
import { AdminSubProjectDelete } from './AdminSubProjectDelete';
import { AdminSubProjectDetails } from './AdminSubProjectDetails';
import { AdminSubProjectSlides } from './AdminSubProjectSlides';
import { AdminSubprojectAnnotations } from './AdminSubprojectAnnotations';
import AdminSubprojectAssignStaticOverlays from './AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.component';

const BASE_SUB_PROJECT_TABS = [
  { name: 'Details' },
  { name: 'Slides', to: 'wsis' },
  { name: 'Algorithms', to: 'algorithms' },
  { name: 'Annotations', to: 'annotations' },
  { name: 'Static Overlays', to: 'static-overlays' },
  {
    name: 'Interactive Overlays',
    to: 'interactive-overlays',
  },
];

export const AdminSubProject: FunctionComponent<{
  role: OrganizationRole;
  sentryRoutes: ReactElement;
  organizationUuid: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}> = ({
  role,
  sentryRoutes,
  organizationUuid,
  rasterTileServerUrl,
  getToken,
}) => {
  const { projectId, subProjectId } = useParams<{
    projectId: string;
    subProjectId: string;
  }>();
  /** Fetch Sub Project */
  const {
    data: fetchSubProjectData,
    loading: fetchSubProjectLoading,
    error: fetchSubProjectError,
  } = useQuery<{
    subProject: SubProject;
  }>(FETCH_ADMIN_SUB_PROJECT_BASE_DETAILS, {
    skip: subProjectId === 'create',
    variables: { subProjectId },
  });

  const subProject = fetchSubProjectData?.subProject;

  const errorView = useHandleSubprojectQueryErrors(fetchSubProjectError);

  if (errorView) {
    return errorView;
  }

  if (!subProjectId || !projectId) {
    // If one pastes this url into a new tab the the location state will be
    // undefined.
    return <Navigate to="admin" />;
  }

  /* SubProject sections */
  const subRoutes = [
    ...BASE_SUB_PROJECT_TABS,
    ...(role.scopes['subproject:delete']
      ? [{ name: 'Delete', to: 'delete' }]
      : []),
  ];

  return (
    <PageLayout
      title={subProject?.name}
      description={subProject?.description}
      loading={fetchSubProjectLoading}
      error={fetchSubProjectError}
      expandableDescription
    >
      <SubNavigation routes={subRoutes} />
      {React.cloneElement(
        sentryRoutes,
        {},
        <>
          {/* Details */}
          <Route path="/" element={<AdminSubProjectDetails />} />

          {/* Slides */}
          <Route
            path={`wsis`}
            element={
              <AdminSubProjectSlides
                subprojectId={subProjectId}
                userRole={role}
                organizationUuid={organizationUuid}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
              />
            }
          />
          <Route
            path={`algorithms`}
            element={
              <AdminSubprojectAlgorithms
                projectId={projectId}
                subprojectId={subProjectId}
                userRole={role}
                organizationUuid={organizationUuid}
              />
            }
          />

          {/* Annotations */}
          <Route
            path={`annotations`}
            element={<AdminSubprojectAnnotations />}
          />

          {/* Static Overlays */}
          <Route
            path={`static-overlays`}
            element={
              <AdminSubprojectAssignStaticOverlays
                subprojectId={subProjectId}
                organizationUuid={organizationUuid}
                userRole={role}
              />
            }
          />

          {/* Interactive Overlays */}
          <Route
            path={`interactive-overlays`}
            element={
              <AdminSubprojectAssignInteractiveOverlays
                subprojectId={subProjectId}
                organizationUuid={organizationUuid}
                userRole={role}
              />
            }
          />

          {/* Delete Subproject */}
          {role.scopes['subproject:delete'] && (
            <Route
              path={`delete`}
              element={
                <AdminSubProjectDelete
                  projectId={projectId}
                  subProjectId={subProjectId}
                  organizationUuid={organizationUuid}
                />
              }
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default React.memo(AdminSubProject);
