import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $Checkbox = styled(motion.div) `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.base};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  margin: 0;
  overflow: hidden;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: inherit;
    appearance: none;
  }
`;
