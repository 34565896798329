import {
  Button,
  Link,
  OneLiner,
  Pill,
  Placeholder,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import React, { ReactElement, useState } from 'react';
import { WsiThumbnail } from '../../../../../components';
import useUserAssignedAssociation from '../../../../../hooks/useUserAssignedAssociation';
import { SubProject, Wsi } from '../../../../../types';
import { WsiMetadataModal } from '../AssignedSlidesTable/components/WsiMetadataModal';
import {
  AddWsiToSubProject,
  RemoveWsiFromSubProject,
} from './useSubProjectSlideAssignment';

type InfoModalState = { state: 'closed' } | { state: 'open'; wsi: Wsi };

export function SlideAssignmentTable({
  subProject,
  wsis,
  onWsiUnassigned,
  onWsiAssigned,
  onAllAssigned,
  totalElements,
  loading,
  organizationUuid,
  rasterTileServerUrl,
  getToken,
  userRole,
}: {
  subProject: SubProject;
  wsis: Wsi[];
  onAllAssigned: () => void;
  onWsiUnassigned: RemoveWsiFromSubProject;
  onWsiAssigned: AddWsiToSubProject;
  totalElements: number;
  loading: boolean;
  organizationUuid: string;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
  userRole: OrganizationRole;
}): ReactElement {
  const [infoModalState, setInfoModalState] = useState<InfoModalState>({
    state: 'closed',
  });
  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();

  if (wsis.length === 0) {
    return (
      <Placeholder
        title="No slides"
        description="There are no slides matching the current filters"
      />
    );
  }
  const showAssociation =
    userAssignedAssociation === null && !userAssignedAssociationLoading;

  const getToolTipText = () => {
    if (totalElements > 2000) {
      return 'You can not assign more than 2000 in one time';
    }
    return '';
  };

  const toolTipText = getToolTipText();

  return (
    <>
      <Table style={{ alignSelf: 'baseline' }}>
        <TableHead>
          <TableRow>
            <TableHeader style={{ width: '44px' }} />
            <TableHeader style={{ width: '30ch' }}>Name</TableHeader>
            {showAssociation ? <TableHeader>Association</TableHeader> : null}
            <TableHeader>Batch</TableHeader>
            <TableHeader>Staining</TableHeader>
            <TableHeader>Tissue</TableHeader>
            <TableHeader>Scanner</TableHeader>
            <TableHeader>Objective Power</TableHeader>
            <TableHeader align="right">
              <Tooltip text={toolTipText} xOffset={'-2em'}>
                {(tooltipProps) => (
                  <div {...tooltipProps}>
                    <Button
                      small
                      onClick={onAllAssigned}
                      style={{ minWidth: 'max-content' }}
                      disabled={loading || totalElements > 2000}
                    >
                      Assign all slides
                    </Button>
                  </div>
                )}
              </Tooltip>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {wsis.map((wsi) => (
            <TableRow key={wsi.id}>
              <TableCell tooltipText={wsi.id}>
                <div style={{ width: '48px', height: '48px' }}>
                  <WsiThumbnail
                    wsiId={wsi.id}
                    getToken={getToken}
                    rasterTileServerUrl={rasterTileServerUrl}
                  />
                </div>
              </TableCell>
              <TableCell tooltipText={wsi.name} style={{ maxWidth: '20ch' }}>
                <Link
                  href={`/${organizationUuid}/admin/wsi/${wsi.id}`}
                  target="_blank"
                >
                  <OneLiner>{wsi.name}</OneLiner>
                </Link>
              </TableCell>
              {showAssociation ? (
                <TableCell tooltipText={wsi.association.name}>
                  {wsi.association.name}
                </TableCell>
              ) : null}
              <TableCell tooltipText={wsi.batchName}>
                {wsi.batchName ?? <Pill role="status" text="null" />}
              </TableCell>
              <TableCell>
                {wsi.staining ?? <Pill role="status" text="null" />}
              </TableCell>
              <TableCell>
                {wsi.tissue ?? <Pill role="status" text="null" />}
              </TableCell>
              <TableCell>
                {wsi.scanner ? (
                  `${wsi.scanner.vendor}${
                    wsi.scanner.model ? ` ${wsi.scanner.model}` : ''
                  }`
                ) : (
                  <Pill role="status" text="null" />
                )}
              </TableCell>
              <TableCell>
                {wsi.objectivePower ?? <Pill role="status" text="null" />}
              </TableCell>
              <TableCell align="right" style={{ padding: '0 12px' }}>
                {wsi.inParent ? (
                  <Button
                    small
                    disabled={loading}
                    variant="primaryOutline"
                    onClick={() => {
                      onWsiUnassigned(wsi.id);
                    }}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    small
                    disabled={loading}
                    variant="primary"
                    onClick={() => {
                      onWsiAssigned(wsi.id);
                    }}
                  >
                    Add
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {infoModalState.state === 'open' ? (
        <WsiMetadataModal
          isOpen={infoModalState.state === 'open'}
          wsiId={infoModalState.wsi.id}
          subProjectId={subProject.id}
          onClose={() => {
            setInfoModalState({ state: 'closed' });
          }}
          getToken={getToken}
          rasterTileServerUrl={rasterTileServerUrl}
          role={userRole}
        />
      ) : null}
    </>
  );
}
