import { Button, Section } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { cacheDeleteSubProject } from '../../../../utils';
import { DELETE_SUB_PROJECT } from './DELETE_SUB_PROJECT.queries';

const AdminSubProjectDelete: FunctionComponent<{
  projectId: string;
  subProjectId: string;
  organizationUuid: string;
}> = ({ projectId, subProjectId, organizationUuid }) => {
  const navigate = useNavigate();
  const [deleteSubProject, { error, loading }] = useMutation<
    unknown,
    { id: string }
  >(DELETE_SUB_PROJECT);

  const handleClick = () => {
    void deleteSubProject({
      variables: { id: subProjectId },
      update: cacheDeleteSubProject(projectId),
    }).then(() => {
      navigate(`/${organizationUuid}/admin/projects/${projectId}/subprojects`);
    });
  };

  return (
    <Section title="Delete Subproject" loading={loading} error={error}>
      <Button onClick={handleClick}>Delete Subproject</Button>
    </Section>
  );
};

export default AdminSubProjectDelete;
