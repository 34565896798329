import {
  Organization,
  OrganizationRole,
  OrganizationUser,
  ROLES,
  User,
} from '@aignostics/core';
import { v4 as uuid } from 'uuid';
import {
  AnnotationCategory,
  Association,
  Case,
  Disease,
  FetchedOnboardingBatch,
  Project,
  SamplePreparation,
  SampleType,
  Scanner,
  Species,
  Staining,
  SubProject,
  Tissue,
  View,
  Wsi,
} from '../../types';

type EntityMocker<T> = (overrides?: Partial<T>) => T;

const datetime = new Date().toISOString();

export const mockedUuid = '00000000-0000-0000-0000-000000000000';

const organization: EntityMocker<Organization> = (overrides = {}) => ({
  id: 1234,
  uuid: '00000000-0000-0000-0000-000000000000',
  name: 'aignostics',
  external_bucket: null,
  ...overrides,
});

const organizationRole: EntityMocker<OrganizationRole> = (overrides = {}) => ({
  organization: organization(),
  isDisabled: false,
  roleName: 'admin',
  scopes: ROLES.admin,
  ...overrides,
});

const user: EntityMocker<User> = (overrides) => ({
  id: 'User ID',
  name: 'User Name',
  email: 'username@aignostics.com',
  avatarUrl: '',
  deleted: false,
  __typename: 'User',
  roleName: 'viewer',
  role: {
    isDisabled: false,
    organization: organization(),
    roleName: 'admin',
    scopes: ROLES.admin,
  },
  roles: [
    {
      isDisabled: false,
      organization: organization(),
      roleName: 'admin',
      scopes: ROLES['admin'],
    },
  ],
  ...overrides,
});

const users: EntityMocker<User[]> = () => [
  user({
    id: '1',
    name: 'Joe Doe',
    email: 'joe.doe@example.com',
    deleted: false,
    inParent: false,
    isNewUser: false,
  }),
  user({
    id: '2',
    name: 'Jane Smith',
    email: 'janesmith@example.com',
    deleted: false,
    inParent: false,
    isNewUser: false,
  }),
];

/** Mocks user entity for testing */
const organizationUser: EntityMocker<OrganizationUser> = (overrides) => ({
  id: 'User ID',
  name: 'User Name',
  email: 'username@aignostics.com',
  avatarUrl: '',
  deleted: false,
  __typename: 'User',
  role: {
    isDisabled: false,
    organization: organization(),
    roleName: 'viewer',
    scopes: ROLES.viewer,
  },
  isDisabled: false,
  ...overrides,
});

const annotationCategory: EntityMocker<AnnotationCategory> = (overrides) => ({
  id: 'Category ID',
  name: 'Category name',
  color: '#000000',
  inParent: true,
  __typename: 'AnnotationCategory',
  ...overrides,
});

const annotationCategories: EntityMocker<AnnotationCategory[]> = () => [
  annotationCategory({
    id: 'Category ID #1',
    name: 'Category #1',
    color: '#000001',
  }),
  annotationCategory({
    id: 'Category ID #2',
    name: 'Category #2',
    color: '#000002',
  }),
  annotationCategory({
    id: 'Category ID #3',
    name: 'Category #3',
    color: '#000003',
  }),
  annotationCategory({
    id: 'Category ID #4',
    name: 'Category #4',
    color: '#000004',
  }),
  annotationCategory({
    id: 'Category ID #5',
    name: 'Category #5',
    color: '#000005',
  }),
];

/* Associations mock */

const associations: EntityMocker<Pick<Association, 'name'>[]> = () => [
  association({
    name: 'camelyon',
  }),
  association({
    name: 'caprera',
  }),
  association({
    name: 'capri',
  }),
  association({
    name: 'chios',
  }),
  association({
    name: 'corfu',
  }),
  association({
    name: 'corsica',
  }),
  association({
    name: 'crete',
  }),
  association({
    name: 'gavi',
  }),
  association({
    name: 'kos',
  }),
  association({
    name: 'leros',
  }),
  association({
    name: 'licosa',
  }),
  association({
    name: 'mallorca',
  }),
  association({
    name: 'ponza',
  }),
  association({
    name: 'ruia',
  }),
  association({
    name: 'samos',
  }),
  association({
    name: 'santorini',
  }),
  association({
    name: 'sardinia',
  }),
  association({
    name: 'tcga',
  }),
  association({
    name: 'TG Gate',
  }),
  association({
    name: 'vis',
  }),
];

const association: EntityMocker<Pick<Association, 'name'>> = (overrides) => ({
  name: 'association-name',
  ...overrides,
});

/* Batches mock */

const onboardingBatchWsi: EntityMocker<FetchedOnboardingBatch['wsis'][0]> = (
  overrides
) => ({
  uuid: 'a8f21ca2-ff57-4fe1-be02-d4230c0a530f',
  name: 'multiplex_without_descriptions_DAPI',
  caseUuid: '09facef6-fe01-4f39-8da5-ba78b7acfd97',
  caseId: 'case_a',
  block: 'block_a',
  section: 'ssssss',
  patientExternalId: 'pppppp',
  staining: 'DAPI',
  tissue: 'Abdomen NOS',
  disease: 'Other',
  samplePreparation: 'Other',
  sampleType: 'Other',
  path: '2024_01_08/6e3407ba-39fa-4e82-9763-0ae3872f1d4b/09facef6-fe01-4f39-8da5-ba78b7acfd97_a8f21ca2-ff57-4fe1-be02-d4230c0a530f.czi',
  fileSize: 3914562716,
  metadata: {
    czi: {
      index: 0,
      channel_name: 'Channel:0',
      original_staining: 'DAPI',
    },
    originalChecksum: 'AyX3dQ==',
    originalFilename: 'multiplex_without_descriptions.czi',
    originalFilesize: 4171600704,
  },
  scannerModel: 'akoya',
  scannerType: 'vectra polaris',
  status: 'onboarded',
  relatedSlide: 'b32bc772-14f1-458a-b9e6-83673730f3cd',
  relationType: 'MULTIPLEX',
  uploadProgress: 'in_progress',
  onboardProgress: [
    {
      name: 'Data Validation',
      status: 'success',
      errors: [],
    },
    {
      name: 'Channel Extraction',
      status: 'success',
      errors: [],
    },
    {
      name: 'Slide Ingestion',
      status: 'success',
      errors: [],
    },
  ],
  originalFilename: 'multiplex_without_descriptions.czi',
  ...overrides,
});

const onboardingBatchWsis: EntityMocker<
  FetchedOnboardingBatch['wsis']
> = () => [
  onboardingBatchWsi({
    uuid: 'batchid-1',
    name: 'batch-wsi1',
    path: 'path1',
    originalFilename: 'filename1',
    block: 'block_a',
    caseId: 'case_a',
  }),
  onboardingBatchWsi({
    uuid: 'batchid-2',
    name: 'batch-wsi2',
    path: 'path2',
    originalFilename: 'filename2',
    block: 'block_b',
    caseId: 'case_b',
  }),
  onboardingBatchWsi({
    uuid: 'batchid-3',
    name: 'batch-wsi3',
    path: 'path3',
    originalFilename: 'filename3',
    block: 'block_c',
    caseId: 'case_c',
  }),
  onboardingBatchWsi({
    uuid: 'batchid-4',
    name: 'batch-wsi4',
    path: 'path4',
    originalFilename: 'filename4',
    block: 'block_d',
    caseId: 'case_d',
  }),
  onboardingBatchWsi({
    uuid: 'batchid-5',
    name: 'batch-wsi5',
    path: 'path4',
    originalFilename: 'filename5',
    block: 'block_e',
    caseId: 'case_e',
  }),
];

const batch: EntityMocker<FetchedOnboardingBatch> = (overrides) => ({
  id: 'batch-test-id-123',
  associationId: '00001',
  batchName: 'test form conversion',
  name: 'batch1',
  status: 'pending',
  wsis: onboardingBatchWsis(),
  species: 'Human',
  associationName: 'sardinia',
  createdAt: '2023-05-15T16:57:21.991Z',
  expectedSlides: 4,
  totalSlideFiles: 12,
  canValidate: true,
  scanMonth: 'January',
  scanYear: '2023',
  createdBy: {
    id: 'user_id',
    name: 'User Name',
    email: 'email@mail.com',
  },
  ...overrides,
});

const batches: EntityMocker<FetchedOnboardingBatch[]> = () => [
  batch(),
  batch({
    id: '00002',
    batchName: 'Batch #2',
  }),
  batch({
    id: '00003',
    batchName: 'Batch #3',
  }),
];

const caseEntity: EntityMocker<Case> = (overrides) => ({
  id: '<Case-ID>',
  name: 'Case Name',
  sid: '<Case-SID>',
  ...overrides,
});

/* Tissues mock */

const tissues: EntityMocker<Tissue[]> = () => [
  tissue({
    id: '28',
    name: 'Adrenal gland',
  }),
  tissue({
    id: '29',
    name: 'Base of tongue',
  }),
  tissue({
    id: '14',
    name: 'Bone Marrow',
  }),
  tissue({
    id: '30',
    name: 'Bones, joints and articular cartilage',
  }),
  tissue({
    id: '31',
    name: 'Brain',
  }),
  tissue({
    id: '1',
    name: 'Breast',
  }),
  tissue({
    id: '23',
    name: 'Bronchus',
  }),
  tissue({
    id: '19',
    name: 'Cell Lines',
  }),
  tissue({
    id: '32',
    name: 'Cervix uteri',
  }),
  tissue({
    id: '18',
    name: 'CNS',
  }),
  tissue({
    id: '2',
    name: 'Colon',
  }),
  tissue({
    id: '55',
    name: 'Colon/Rectum',
  }),
  tissue({
    id: '27',
    name: 'Colorectal Mucosa',
  }),
  tissue({
    id: '33',
    name: 'Connective, subcutaneous and other soft tissues',
  }),
  tissue({
    id: '34',
    name: 'Corpus uteri',
  }),
  tissue({
    id: '9',
    name: 'Diffuse large B cell lymphoma (DLBCL)',
  }),
  tissue({
    id: '21',
    name: 'Endometrium',
  }),
  tissue({
    id: '35',
    name: 'Esophagus',
  }),
  tissue({
    id: '36',
    name: 'Eye',
  }),
  tissue({
    id: '37',
    name: 'Gallbladder',
  }),
  tissue({
    id: '26',
    name: 'Gastric Mucosa',
  }),
  tissue({
    id: '38',
    name: 'Heart',
  }),
];

const tissue: EntityMocker<Tissue> = (overrides) => ({
  id: '0001',
  name: 'tissue-name',
  ...overrides,
});

/* Diseases mock */

const diseases: EntityMocker<Disease[]> = () => [
  disease({
    name: 'NSCLC',
  }),
  disease({
    name: 'Other',
  }),
];

const disease: EntityMocker<Disease> = (overrides) => ({
  name: 'disease-name',
  ...overrides,
});

/* Sample prparations mock */

const samplePreparations: EntityMocker<SamplePreparation[]> = () => [
  samplePreparation({
    name: 'Fresh frozen',
  }),
  samplePreparation({
    name: 'FFPE',
  }),
  samplePreparation({
    name: 'Other',
  }),
];

const samplePreparation: EntityMocker<SamplePreparation> = (overrides) => ({
  name: 'sample-preparation-name',
  ...overrides,
});

/* Sample types mock */

const sampleTypes: EntityMocker<SampleType[]> = () => [
  sampleType({
    name: 'Resection',
  }),
  sampleType({
    name: 'Biopsy',
  }),
  sampleType({
    name: 'TMA',
  }),
  sampleType({
    name: 'Spot',
  }),
  sampleType({
    name: 'Other',
  }),
];

const sampleType: EntityMocker<SampleType> = (overrides) => ({
  name: 'sample-type-name',
  ...overrides,
});

/* Species mock */

const species: EntityMocker<Species[]> = () => [
  speciesSingle({
    name: 'Human',
  }),
  speciesSingle({
    name: 'Mouse',
  }),
  speciesSingle({
    name: 'Rat',
  }),
];

const speciesSingle: EntityMocker<Species> = (overrides) => ({
  name: 'species-id',
  ...overrides,
});

/* Stainings mock */

const stainings: EntityMocker<Staining[]> = () => [
  staining({
    id: '166',
    name: 'af488',
  }),
  staining({
    id: '167',
    name: 'af555',
  }),
  staining({
    id: '92',
    name: 'ALK',
  }),
  staining({
    id: '2',
    name: 'AMACR',
  }),
  staining({
    id: '3',
    name: 'Amyloid',
  }),
  staining({
    id: '185',
    name: 'Autofluorescence',
  }),
  staining({
    id: '6',
    name: 'bcl',
  }),
  staining({
    id: '1',
    name: 'AE1',
  }),
  staining({
    id: '70',
    name: 'AE1-AE3',
  }),
  staining({
    id: '5',
    name: 'Bcl-2',
  }),
  staining({
    id: '7',
    name: 'BCL2',
  }),
  staining({
    id: '289',
    name: 'BCL6',
  }),
  staining({
    id: '290',
    name: 'Beta-Catenin',
  }),
  staining({
    id: '116',
    name: 'blue',
  }),
  staining({
    id: '291',
    name: 'Calretinin',
  }),
];

const staining: EntityMocker<Staining> = (overrides) => ({
  id: '00001',
  name: 'staining-name',
  ...overrides,
});

/* Scanners mock */

const scanners: EntityMocker<Scanner[]> = () => [
  scannerEntity({
    id: '1342',
    vendor: 'akoya',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '1318',
    vendor: 'aperio',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1344',
    vendor: 'aperio',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '1347',
    vendor: 'aperio',
    model: 'GT 450 DX',
  }),
  scannerEntity({
    id: '1',
    vendor: 'aperio',
    model: null,
  }),
  scannerEntity({
    id: '1335',
    vendor: 'generic-tiff',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1343',
    vendor: 'generic-tiff',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '10',
    vendor: 'generic-tiff',
    model: null,
  }),
  scannerEntity({
    id: '7',
    vendor: 'hamamatsu',
    model: null,
  }),
  scannerEntity({
    id: '1336',
    vendor: 'leica',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1346',
    vendor: 'mirax',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1345',
    vendor: 'mirax',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '4',
    vendor: 'mirax',
    model: null,
  }),
];

const scannerEntity: EntityMocker<Scanner> = (overrides) => ({
  id: 'scanner-id',
  model: 'Model',
  vendor: 'Vendor',
  ...overrides,
});

const view: EntityMocker<View> = (overrides) => ({
  width: 1024,
  height: 768,
  maxZoom: 11,
  tileSize: 256,
  createdAt: datetime,
  updatedAt: datetime,
  ...overrides,
});

/** Mocks wsi entity for testing */
const wsi: EntityMocker<Wsi> = (overrides) => ({
  ...view(overrides),
  id: `Slide ID`,
  name: `Slide Name`,
  originalName: `Slide Name`,
  path: `Slide Path`,
  batchName: '',
  nextWsiIdWithoutAnnotations: null,
  subsequentWsiIds: {
    nextWsiId: null,
    previousWsiId: null,
    currentSlideIndex: 1,
    totalCount: 1,
  },
  bounds: [],
  block: 'block',
  patient: 'patient',
  stainings: [],
  fileSize: 243433432,
  onboardProgress: [
    {
      status: 'success',
    },
  ],
  overlays: [],
  overlaysAll: [],
  overlaysAssigned: [],
  overlaysCount: 0,
  section: 'section',
  patientExternalId: 'patientExternalId',
  annotations: [],
  annotationsCount: 0,
  objectivePower: 20,
  mppx: 0.5,
  mppy: 0.5,
  brightfieldCount: 0,
  layersVisibleCount: 0,
  inParent: false,
  focusArea: undefined,
  fluorescence: {
    totalCount: 0,
    totalActiveCount: 0,
    files: [],
  },
  fluorescenceCount: 0,

  taggers: [],
  taggersAll: [],
  taggersAssigned: [],
  taggersCount: 0,

  __typename: 'WSI',
  tissue: 'lung',
  histogram: [],
  case: {
    id: 'case-id',
    name: 'Case Name',
    sid: 'case-sid',
  },
  association: {
    id: 'gavi',
    name: 'Gavi',
  },
  staining: 'he',
  scanner: scannerEntity({ id: '12', model: 'DP200', vendor: 'Ventana' }),
  disease: null,
  samplePreparation: null,
  sampleType: null,
  uuid: 'mocked-wsi-uuid',
  ...overrides,
});

const wsiMetdataInfo: Wsi = wsi({
  name: `Renamed Slide`,
  id: `Renamed Slide Id`,
  subProject: `Subproject ID`,
});

const wsis: EntityMocker<Wsi[]> = () => [
  wsi({
    id: 'slide1',
    name: 'slide1',
    path: 'slide1',
    width: 1024,
    height: 768,
    maxZoom: 11,
    tileSize: 256,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid1',
  }),
  wsi({
    id: 'slide2',
    name: 'slide2',
    path: 'slide2',
    width: 2024,
    height: 868,
    maxZoom: 15,
    tileSize: 300,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid2',
  }),
  wsi({
    id: 'slide3',
    name: 'slide3',
    path: 'slide3',
    width: 3024,
    height: 968,
    maxZoom: 121,
    tileSize: 156,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid3',
  }),
];

const subProject: EntityMocker<SubProject> = (overrides) => ({
  id: uuid(),
  projectId: uuid(),
  nextWsiId: uuid(),
  previousWsiId: uuid(),
  nextWsiIdWithoutAnnotations: uuid(),
  wsisCount: 0,
  annotatedWsisCount: 0,
  thumbnail: 'Subproject Thumbnail',
  name: 'Subproject Name',
  description: 'Subproject Description',
  annotationFeature: 'OFF',
  otherAnnotationVisibility: 'OFF',
  showCsv: false,
  overlaysCount: '12',
  annotationCategories: [],
  annotators: [],
  diseases: [],
  inParent: false,
  overlayBy: 'block',
  createdBy: {
    id: '123',
    name: 'Subproject Creator',
    email: 'creator@mail.com',
    deleted: false,
    __typename: 'User',
    avatarUrl: '',
    isDisabled: false,
  },
  createdAt: datetime,
  updatedAt: datetime,
  filteredWsis: [],
  registrationGroups: [],
  wsis: {
    nodes: [],
    pageInfo: {
      page: 1,
      totalElements: 0,
      totalPages: 0,
    },
    collectionAttributes: {
      totalAnnotated: 0,
    },
  },
  __typename: 'SubProject',
  taggerNames: ['23123', 'xasd123', 'asd123'],
  isVisible: true,
  annotatedUserIds: ['userId_1', 'userId_3', 'userId_4'],
  annotationDrawingTools: {
    pen: false,
    picker: false,
    brush: false,
  },
  ...overrides,
});

const project: EntityMocker<Project> = (overrides) => ({
  id: 'Project ID',
  name: 'Project Name',
  description: 'Project Description',
  thumbnailWsiId: 'first-wsi-id',
  subProjects: {
    nodes: [],
    pageInfo: { page: 1, totalElements: 0, totalPages: 0 },
    collectionAttributes: { availableSubProjectCount: 0 },
  },
  subProjectsCount: 0,
  users: [],
  usersCount: 0,
  createdBy: {
    id: '123',
    name: 'Subproject Creator',
    email: 'creator@mail.com',
    deleted: false,
    __typename: 'User',
    avatarUrl: '',
    isDisabled: false,
  },
  createdAt: datetime,
  updatedAt: datetime,
  __typename: 'Project',
  isVisible: true,
  assignedUsers: [{ id: 'userOneId', name: 'userOneName' }],
  ...overrides,
});

const projects: EntityMocker<Project[]> = () => [
  mock.project({
    id: '1_ProjectId',
    name: '1 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '1_CreatedById',
      email: '1@1.com',
      name: ' 1 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '2_ProjectId',
    name: '2 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '2_CreatedById',
      email: '2@2.com',
      name: ' 2 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '3ProjectId',
    name: '3 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '3CreatedById',
      email: '3@3.com',
      name: ' 3 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '4ProjectId',
    name: '3 Project longer name',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '3CreatedById',
      email: '3@3.com',
      name: ' 3 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
];

export const mock = {
  annotationCategory,
  annotationCategories,
  association,
  associations,
  batch,
  batches,
  case: caseEntity,
  diseases,
  organization,
  organizationUser,
  organizationRole,
  samplePreparations,
  sampleTypes,
  scanner: scannerEntity,
  scanners,
  stainings,
  species,
  subProject,
  project,
  projects,
  tissues,
  user,
  users,
  wsiMetdataInfo,
  wsi,
  wsis,
};
