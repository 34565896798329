import React, { useLayoutEffect, useRef, useState, } from 'react';
import Tooltip from '../Tooltip';
import { $OneLiner } from './OneLiner.styles';
/**
 * Makes sure that passed text stays on a single line and does not overflow its
 * container. Long text will get cut off with ellipsis `…` and show a tooltip on
 * hover.
 */
function OneLiner({ children, tooltip: overrideTooltip, ...rest }) {
    const textRef = useRef(null);
    const [tooltip, setTooltip] = useState(overrideTooltip);
    // Check if text content is cut off and set tooltip in that case.
    useLayoutEffect(() => {
        if (overrideTooltip) {
            setTooltip(overrideTooltip);
            return;
        }
        const textNode = textRef.current;
        if (textNode === null)
            return;
        const scrollWidth = textNode.scrollWidth;
        const textWidth = textNode.offsetWidth;
        if (scrollWidth <= textWidth) {
            return;
        }
        setTooltip(textNode.innerText);
    }, [overrideTooltip]);
    return (React.createElement(Tooltip, { text: tooltip }, (tooltipProps) => (React.createElement($OneLiner, { ref: textRef, ...rest, ...tooltipProps }, children))));
}
export default OneLiner;
