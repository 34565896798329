import {
  OnboardingReadOnlyBatchHeader,
  PageLayout,
  Section,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import dateFormat from 'date-fns/format';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import { OnboardingBatchReadOnlyTableEntry } from '../../components/ReadOnly/OnboardingBatch.ReadOnlyTableEntry.component';
import { OnboardingProgressErrorsModal } from '../../components/ReadOnly/OnboardingProgressErrorsModal.component';
import { ONBOARDING_BATCH_COMPLETED_STATUSES } from '../../components/ReadOnly/const';
import { useFetchOnboardingWsisQuery } from '../../hooks/useFetchOnboardingWsisQuery';
import { useOnboardingCompletedNotification } from '../../hooks/useOnboardingCompletedNotification';
import useUserAssignedAssociation from '../../hooks/useUserAssignedAssociation';
import { useValidateOnboardingBatch } from '../../hooks/useValidateOnboardingBatch';
import { convertRecordsToState } from '../SetFileMetadataStep/Form/form.state';
import {
  $OnboardingReadOnlyTableWrapper,
  $PreviousEntriesHeadline,
} from './Admin.DataOnboardingBatch.styles';
import {
  mapStatusToTutorialStep,
  renderOnboardingBatchSubHeader,
} from './Admin.DataOnboardingBatch.utils';

const APOSTROPHE = '\u2019';

type AdminDataOnboardingProps = {
  role: OrganizationRole;
  currentUser: User;
};

const AdminDataOnboardingBatch: React.FC<AdminDataOnboardingProps> = ({
  role,
  currentUser,
}) => {
  const { batchId } = useParams() as { batchId: string };
  const onboardingProgressErrorsDialog = useDisclosure();

  const {
    addBatchSucceededSnackbar,
    addBatchSucceededWithErrorsSnackbar,
    addBatchFailedSnackbar,
  } = useOnboardingCompletedNotification();

  const { data, loading, error } = useFetchOnboardingWsisQuery({
    variables: { batchId, injectBlackLayerReferences: false },
    onCompleted: (data) => {
      if (data?.onboardingBatch?.length) {
        const { status, onboardingErrors, succeededSlidesCount, slides } =
          convertRecordsToState(data.onboardingBatch[0]);

        if (ONBOARDING_BATCH_COMPLETED_STATUSES.includes(status)) {
          if (status !== 'failed') {
            if (onboardingErrors.length) {
              addBatchSucceededWithErrorsSnackbar(() => {
                onboardingProgressErrorsDialog.open();
              });
            } else {
              addBatchSucceededSnackbar(succeededSlidesCount === slides.length);
            }
          } else {
            addBatchFailedSnackbar();
          }
        }
      }
    },
  });

  const latestBatchId = data?.onboardingBatch?.[0]
    ? data.onboardingBatch[0].id
    : null;

  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();
  const showAssociations =
    userAssignedAssociation === null && !userAssignedAssociationLoading;

  const { validateOnboardingBatch, isValidating } = useValidateOnboardingBatch({
    onSuccess() {
      setValidationSuccessfullyTriggered(true);
    },
    batchId: latestBatchId,
  });

  const [
    isValidationSuccessfullyTriggered,
    setValidationSuccessfullyTriggered,
  ] = useState(false);

  if (loading || error || !data) {
    return <PageLayout loading={loading} error={error} />;
  }

  if (!data.onboardingBatch || data.onboardingBatch.length === 0) {
    return (
      <PageLayout
        error={new Error(`Couldn${APOSTROPHE}t find batch ${batchId}`)}
      />
    );
  }

  const onboardingBatches = data.onboardingBatch.map(convertRecordsToState);

  const {
    batch_name,
    species,
    association,
    status,
    createdAt,
    createdBy,
    onboardingErrors,
  } = onboardingBatches[0];
  const { header, subHeader, headerIcon } =
    renderOnboardingBatchSubHeader(status);
  const formattedCreatedAt = dateFormat(new Date(createdAt), 'yyyy-MM-dd');
  const createdBySuffixMsg = createdBy
    ? ` by ${createdBy.name || createdBy.email}`
    : ' by Unknown User';

  return (
    <>
      <PageLayout title={batch_name}>
        <Section
          background="white"
          loading={loading || (isValidating && status === 'pending')}
        >
          <p>{`Batch uploaded on ${formattedCreatedAt}${createdBySuffixMsg}`}</p>
        </Section>

        <Section
          aria-label="Data Onboarding Batch Section"
          title={header}
          titleIcon={headerIcon}
          description={subHeader}
        >
          <OnboardingReadOnlyBatchHeader
            name={batch_name}
            species={species}
            association={association}
            showAssociations={showAssociations}
          />
          <$OnboardingReadOnlyTableWrapper>
            {onboardingBatches.map((onboardingBatch, index) => (
              <React.Fragment key={onboardingBatch.batch_id}>
                <OnboardingBatchReadOnlyTableEntry
                  onboardingBatch={onboardingBatch}
                  isLatestBatchEntry={
                    index ===
                    0 /* first batch in the list is the latest batch */
                  }
                  onBatchValidationTrigger={validateOnboardingBatch}
                  isValidating={isValidating}
                  isValidationSuccessfullyTriggered={
                    isValidationSuccessfullyTriggered
                  }
                  role={role}
                  currentUser={currentUser}
                />
                {index === 0 && onboardingBatches.length > 1 && (
                  <$PreviousEntriesHeadline>
                    Previous entries
                  </$PreviousEntriesHeadline>
                )}
              </React.Fragment>
            ))}
          </$OnboardingReadOnlyTableWrapper>
        </Section>
      </PageLayout>

      <OnboardingTutorialModal initialStep={mapStatusToTutorialStep(status)} />

      {onboardingErrors?.length > 0 && (
        <OnboardingProgressErrorsModal
          isVisible={onboardingProgressErrorsDialog.isOpen}
          onClose={onboardingProgressErrorsDialog.close}
          onboardingErrors={onboardingErrors}
        />
      )}
    </>
  );
};

export default AdminDataOnboardingBatch;
