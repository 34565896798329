import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { Annotation } from '../../../../api-types';
import { useViewerController } from '../../../__Viewer/ViewerController';
import {
  FeatureRef,
  useActiveFeature,
  useSetActiveFeature,
} from '../../../__Viewer/ViewerLayerState/ActiveFeatureProvider';
import {
  FeatureInspection,
  flowDirection,
} from '../../__FeatureBar/FeatureInspection/FeatureInspection.component';
import {
  flyToFeatureInspection,
  flyToFeaturesExtent,
} from '../../__FeatureBar/FeatureInspection/flyTo';
import { getNextIndex, getPrevIndex } from './utils';

interface AnnotationNavigationProps {
  categoryAnnotations: Annotation[];
  height: number;
  name: string;
  onNavigation: () => void;
}

const initIndex = (
  activeFeature: FeatureRef | null,
  activeUsersAnnotations: Annotation[]
): number | null => {
  if (activeFeature?.type !== 'annotation') return null;

  const index = activeUsersAnnotations.findIndex(
    (a) => a.id === activeFeature?.id
  );

  if (index === -1) return null;

  return index;
};

export function AnnotationNavigation({
  categoryAnnotations,
  height,
  name,
  onNavigation,
}: AnnotationNavigationProps): ReactElement {
  const lastActiveAnnotation = useRef<{
    annotation: Annotation;
    index: number;
  }>();
  const theme = useTheme();
  const { activeViewer } = useViewerController();
  const map = activeViewer.map;

  const activeFeature = useActiveFeature();
  const setActiveAnnotation = useSetActiveFeature();

  const [annotationIndex, setAnnotationIndex] = useState<number | null>(() => {
    const index = initIndex(activeFeature, categoryAnnotations);

    if (index !== null) {
      lastActiveAnnotation.current = {
        annotation: categoryAnnotations[index],
        index,
      };
    }

    return index;
  });

  const flyToPadding = useMemo(
    () => [
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
    ],
    [theme]
  );

  const handleFlyToAnnotation = useCallback(
    (annotation: Annotation) => {
      setActiveAnnotation({
        id: annotation.id,
        type: 'annotation',
      });
      flyToFeatureInspection(annotation, height, map, flyToPadding);
    },
    [height, map, setActiveAnnotation, flyToPadding]
  );

  const handleFlyToCategory = () => {
    onNavigation();
    flyToFeaturesExtent(categoryAnnotations, height, map, flyToPadding);
  };

  // Set the index to the last active annotation when annotations change if
  // still visible
  useEffect(() => {
    // Skip if we didn't have a previously set annotation
    if (lastActiveAnnotation.current === undefined) return;

    // Skip if previous annotation is still at the same index
    if (
      lastActiveAnnotation.current.annotation.id ===
      categoryAnnotations[lastActiveAnnotation.current.index]?.id
    ) {
      return;
    }

    const lastActiveAnnotationIndex = categoryAnnotations.findIndex(
      (annotation) =>
        annotation.id === lastActiveAnnotation.current?.annotation.id
    );

    const lastActiveAnnotationFound = lastActiveAnnotationIndex >= 0;
    const newIndex = lastActiveAnnotationFound
      ? lastActiveAnnotationIndex
      : null;

    if (newIndex !== null && lastActiveAnnotation.current !== undefined) {
      lastActiveAnnotation.current = {
        ...lastActiveAnnotation.current,
        index: newIndex,
      };
    }
    setAnnotationIndex(newIndex);
  }, [categoryAnnotations, handleFlyToAnnotation]);

  const onNavigationToAnnotation = (direction: flowDirection) => {
    onNavigation();
    const index =
      direction === 'prev'
        ? getPrevIndex(categoryAnnotations, annotationIndex)
        : getNextIndex(categoryAnnotations, annotationIndex);

    if (index >= categoryAnnotations.length) {
      throw new Error('Invariant violation: navigation to non-existent index');
    }
    const annotation = categoryAnnotations[index];
    // Keep track of last active annotation for index recalculation
    lastActiveAnnotation.current = {
      annotation,
      index,
    };
    setAnnotationIndex(index);
    handleFlyToAnnotation(annotation);
  };

  return (
    <FeatureInspection
      isGroupFocusable={true}
      label={{
        currentIndex: annotationIndex,
        totalCount: categoryAnnotations.length,
      }}
      name={name}
      onFlyToCategoryClick={handleFlyToCategory}
      onNavigation={onNavigationToAnnotation}
      disableNavigation={false}
    />
  );
}

export default AnnotationNavigation;
