import { ApolloClient, ApolloError } from '@apollo/client';
import { create } from 'zustand';
import { FocusArea } from '../../../../api-types';
import { UPDATE_ROI } from '../../../../graphql/mutations';
import { useDrawingToolSettingsStore } from '../../Drawing/useDrawingToolSettingsStore';

export type ROISettings = {
  latestRegionSize: number;
  isEditable: boolean;
  regionInfo: FocusArea;
};

interface RegionsSettingsStore {
  selectedRegionId: string | null;
  setSelectedRegionId: (newROIId: string | null) => void;
  allROIsMap: Record<string, ROISettings>;
  getSelectedROI: () => FocusArea | null;
  setAllROIs: (newROI: Record<string, ROISettings>) => void;
  getAllROIs: () => Record<string, ROISettings>;
  setSelectedROI: (newROI: FocusArea | null) => void;
  selectedROI: FocusArea | null;
  updateROISize: (id: string, newSize: number) => void;
  setRegionSizeInDrawingStore: (newSize: number) => void;
  updateIsROIEditable: (id: string, editable: boolean) => void;
  // update ROIs
  isSavingUpdate: boolean;
  updateError: ApolloError | null;
  updateROIinDB: (
    client: ApolloClient<object>,
    wsiId: string,
    subProjectId: string
  ) => void;
  // reset whole store
  reset: () => void;
}

const initialState = {
  selectedRegionId: null,
  allROIsMap: {},
  selectedROI: null,
  isSavingUpdate: false,
  updateError: null,
};

export const useRegionsSettingsStore = create<RegionsSettingsStore>(
  (set, get) => ({
    ...initialState,
    // Viewer
    getSelectedROI: () => get().selectedROI,
    setAllROIs: (newROIs: Record<string, ROISettings>) => {
      set({ allROIsMap: newROIs });
    },
    getAllROIs: () => get().allROIsMap,
    setSelectedRegionId: (newRegionId: string | null) => {
      set({ selectedRegionId: newRegionId });
    },
    setSelectedROI: (newEditableROI) => {
      set({ selectedROI: newEditableROI });
    },
    updateROISize: (id, newSize) => {
      set((state) => {
        const roi = state.allROIsMap[id];
        state.setRegionSizeInDrawingStore(newSize);
        return {
          allROIsMap: {
            ...state.allROIsMap,
            [id]: {
              ...roi,
              latestRegionSize: newSize,
            },
          },
        };
      });
    },
    setRegionSizeInDrawingStore: (size) => {
      const drawingToolStore = useDrawingToolSettingsStore.getState();
      drawingToolStore.setLatestRegionSize(size);
    },
    updateIsROIEditable: (id, editable) => {
      set((state) => {
        const roi = state.allROIsMap[id];
        return {
          allROIsMap: {
            ...state.allROIsMap,
            [id]: {
              ...roi,
              isEditable: editable,
            },
          },
        };
      });
    },
    // Mutation action
    updateROIinDB: async (
      apolloClient: ApolloClient<object>,
      subProjectId: string,
      wsiId: string
    ) => {
      set({ isSavingUpdate: true, updateError: null });

      const newRoi = get().selectedROI;
      if (!newRoi) return;

      try {
        await apolloClient.mutate({
          mutation: UPDATE_ROI,
          variables: {
            subProjectId,
            wsiId,
            polygon: newRoi.geometry,
            regionId: newRoi.id,
          },
          update: (cache) => {
            cache.modify({
              id: cache.identify({
                __typename: 'RegionOfInterest',
                id: newRoi.id,
              }),
              fields: {
                geometry() {
                  return newRoi.geometry;
                },
              },
            });
          },
        });

        set({ isSavingUpdate: false });
      } catch (error) {
        if (error instanceof ApolloError) {
          set({ updateError: error, isSavingUpdate: false });
        }
      }
    },
    reset: () => {
      set(initialState);
    },
  })
);
