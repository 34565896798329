import styled from 'styled-components';

export const $AdminDashboardListContainer = styled.div`
  display: flex;
  direction: row;
  gap: ${({ theme }) => `${theme.spacings[16]}px`};
`;

export const $AdminDashboardWrapper = styled.div`
  margin: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.FULL}px) {
    width: ${({ theme }) => theme.breakpoints.TRIPLE}px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.TRIPLE}px) {
    width: ${({ theme }) => theme.breakpoints.DOUBLE}px;

    ${$AdminDashboardListContainer} {
      flex-direction: column;
    }
  }
`;

export const $Header = styled.div`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: ${({ theme }) => `${theme.spacings['96']}px`};
`;

export const $HeaderTitle = styled.h1`
  overflow: hidden;
  ${({ theme }) => theme.fontStyles.display};
  color: ${({ theme }) => theme.colors.accentPeach};
`;

export const $HeaderDescription = styled.p`
  ${({ theme }) => theme.fontStyles.base};
  margin-bottom: ${({ theme }) => theme.spacings.large}px;

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const $AdminDashboardBackgroundBottomPattern = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 62.5vw;
`;
