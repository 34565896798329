import { gql } from '@apollo/client';

export const FETCH_ANNOTATIONS_CATEGORY_SETS = gql`
  query GET_ANNOTATIONS_CATEGROY_SETS($setName: String) {
    annotationCategorySets(setName: $setName) {
      id
      code
      name
      modules {
        moduleId
        moduleName
      }
    }
  }
`;
