import {
  Button,
  Modal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDynamicList } from '../../../../../../utils/DynamicList/dynamic';
import { UPDATE_INTERACTIVE_OVERLAY } from '../../../Overlays/Overlays.queries';
import { OverlaySlidesTable } from '../../../components/OverlaySlidesTable';
import { UnsavedChangesModal } from '../../../components/UnsavedChanges.modal';
import { InteractiveOverlayWithAssignedSlides } from '../../AdminSubProjectAssignInteractiveOverlays.utils';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../../FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { Tag } from '../ColorSelectorModal/ColorSelectorModal.types';
import { InteractiveOverlayClasses } from './InteractiveOverlayClasses';
import { InteractiveOverlayDeleteConfirmationModal } from './InteractiveOverlayDeleteConfirmation.modal';
import { InteractiveOverlayGeneralTable } from './InteractiveOverlayGeneralTable';
import { Stage } from './SelectStage';
import {
  $ModalContent,
  $ModalFooter,
  $ModalTitle,
} from './interactiveOverlay.modal.styles';
import {
  getUpdates,
  useFetchTagsData,
  useOnPositionUpdate,
} from './interactiveOverlay.utils';

export type WsiItem = {
  name: string;
  id: string;
};

type InteractiveOverlayModalProps = {
  // interactiveOverlay = tagger
  selectedInteractiveOverlay: InteractiveOverlayWithAssignedSlides;
  subprojectId: string;
  onClose: () => void;
  wsiList: WsiItem[];
  organizationUuid: string;
};

export type ToggleInteractiveOverlay = {
  checked: boolean;
  wsiId: string;
  initialChecked: boolean;
};

export const InteractiveOverlayModal = ({
  selectedInteractiveOverlay,
  subprojectId,
  onClose,
  wsiList,
  organizationUuid,
}: InteractiveOverlayModalProps): JSX.Element => {
  const { addSnackbar } = useSnackbarMutations();

  const [interactiveOverlayClasses, setInteractiveOverlayClasses] = useState<
    Tag[]
  >([]);

  const [initialTags, setInitialTags] = useState<Tag[]>([]);
  const [overrideName, setOverrideName] = useState<string | undefined>(
    selectedInteractiveOverlay.overrideName
  );
  const [selectedStage, setSelectedStage] = useState<Stage>(
    selectedInteractiveOverlay.stage
  );

  const initializeInteractiveOverlayMap = () => {
    const valueMap = new Map<string, ToggleInteractiveOverlay>();
    wsiList.forEach((wsi) => {
      const isDisabled = !selectedInteractiveOverlay.allWsis?.includes(wsi.id);

      const isCurrentWsiAssigned =
        selectedInteractiveOverlay.assignedWsis?.includes(wsi.id);

      if (!isDisabled) {
        valueMap.set(wsi.id, {
          checked: isCurrentWsiAssigned,
          initialChecked: isCurrentWsiAssigned,
          wsiId: wsi.id,
        });
      }
    });

    return valueMap;
  };

  const [toggledInteractiveOverlaySlides, setToggledInteractiveOverlaySlides] =
    useState<Map<string, ToggleInteractiveOverlay>>(
      initializeInteractiveOverlayMap
    );

  const deleteInteractiveOverlayConfirmationModal = useDisclosure<string[]>();
  const unsavedChangesModal = useDisclosure();

  useEffect(() => {
    setSelectedStage(selectedInteractiveOverlay.stage);
  }, [selectedInteractiveOverlay.stage]);

  useEffect(() => {
    setOverrideName(selectedInteractiveOverlay.overrideName);
  }, [selectedInteractiveOverlay.overrideName]);

  const [updateInteractiveOverlay] = useMutation(UPDATE_INTERACTIVE_OVERLAY, {
    onCompleted() {
      addSnackbar({
        message: 'Interactive overlay updated successfully!',
        closesAfter: 3000,
        type: 'success',
      });
    },
    onError() {
      addSnackbar({
        message: 'Interactive overlay was not updated!',
        type: 'error',
      });
    },
  });
  const loading = useFetchTagsData(
    subprojectId,
    selectedInteractiveOverlay,
    setInteractiveOverlayClasses,
    setInitialTags
  );

  const onPositionUpdate = useOnPositionUpdate(
    interactiveOverlayClasses,
    setInteractiveOverlayClasses
  );

  const updateInteractiveOverlayClassColor = useCallback(
    (index) => (color: string) => {
      const newItems = [...interactiveOverlayClasses];
      newItems[index] = {
        ...interactiveOverlayClasses[index],
        color,
      };
      setInteractiveOverlayClasses(newItems);
    },
    [interactiveOverlayClasses, setInteractiveOverlayClasses]
  );
  const dynamicListItemProps = useDynamicList({
    items: interactiveOverlayClasses,
    onPositionUpdate,
  });

  const didInteractiveOverlayValuesChange = () => {
    const isTagsChanged = !isEqual(initialTags, interactiveOverlayClasses);
    const initialOverrideName = selectedInteractiveOverlay.overrideName;
    const isOverrideNameChanged = initialOverrideName !== overrideName;
    const interactiveOverlaySlidesToggled =
      isInteractiveOverlaySlidesToggleChanged();
    const isStageChanged = selectedStage !== selectedInteractiveOverlay.stage;
    return (
      isTagsChanged ||
      isOverrideNameChanged ||
      interactiveOverlaySlidesToggled ||
      isStageChanged
    );
  };

  const isInteractiveOverlaySlidesToggleChanged = () => {
    let interactiveOverlaySlidesToggled = false;
    for (const value of toggledInteractiveOverlaySlides.values()) {
      if (value.checked !== value.initialChecked) {
        interactiveOverlaySlidesToggled = true;
        break;
      }
    }
    return interactiveOverlaySlidesToggled;
  };

  const onDeleteIconClick = (taggerId: string) => {
    deleteInteractiveOverlayConfirmationModal.open([taggerId]);
  };

  const onCloseDialog = () => {
    const isChanged = didInteractiveOverlayValuesChange();
    if (!isChanged) {
      onClose();
      setOverrideName(undefined);
      setInteractiveOverlayClasses([]);
      setInitialTags([]);
    }
    if (isChanged) {
      unsavedChangesModal.open();
    }
  };

  const onInteractiveOverlayNameChange = (value: string) => {
    setOverrideName(value);
  };

  const handleInteractiveOverlayWsiToggled = (
    checked: boolean,
    wsiId: string
  ) => {
    const toggleMapped = new Map(toggledInteractiveOverlaySlides);
    const value = toggleMapped.get(wsiId);
    if (!value) {
      throw new Error('value is undefined');
    }
    toggleMapped.set(wsiId, { ...value, checked });

    setToggledInteractiveOverlaySlides(toggleMapped);
  };

  const onUnsavedChangesModalClose = () => {
    unsavedChangesModal.close();
  };

  const onUnsavedChangesModalSave = () => {
    unsavedChangesModal.close();

    void submitInteractiveOverlayChanges();
  };

  const onUnsavedChangesModalDiscard = () => {
    unsavedChangesModal.close();
    onClose();
    setOverrideName(undefined);
    setInteractiveOverlayClasses([]);
    setInitialTags([]);
  };

  const submitInteractiveOverlayChanges = async () => {
    await updateInteractiveOverlay({
      variables: {
        subprojectId,
        updates: getUpdates(
          toggledInteractiveOverlaySlides,
          interactiveOverlayClasses,
          selectedInteractiveOverlay,
          selectedStage,
          overrideName
        ),
      },
      refetchQueries: [FETCH_INTERACTIVE_OVERLAYS_MATRIX],
    });
    onClose();
    setOverrideName(undefined);
    setInteractiveOverlayClasses([]);
    setInitialTags([]);
  };

  const allSlidesAreDisabled = useMemo(() => {
    const isAllToggledSlidesDisabled = Array.from(
      toggledInteractiveOverlaySlides.values()
    ).every(({ checked }) => !checked);
    return isAllToggledSlidesDisabled;
  }, [toggledInteractiveOverlaySlides]);

  return (
    <>
      <Modal
        isVisible={Boolean(selectedInteractiveOverlay.id)}
        onClose={onCloseDialog}
        hasCloseButton
        size="large"
      >
        <$ModalContent>
          <$ModalTitle>{selectedInteractiveOverlay.originalName}</$ModalTitle>
          <InteractiveOverlayGeneralTable
            overrideName={overrideName}
            selectedStage={selectedStage}
            onDeleteIconClick={onDeleteIconClick}
            onInteractiveOverlayNameChange={onInteractiveOverlayNameChange}
            getSelectedStage={setSelectedStage}
            selectedInteractiveOverlay={selectedInteractiveOverlay}
            allSlidesAreDisabled={allSlidesAreDisabled}
          />
          <div style={{ paddingBottom: '40px' }}>
            <InteractiveOverlayClasses
              loading={loading}
              classes={interactiveOverlayClasses}
              dynamicListItemProps={dynamicListItemProps}
              updateInteractiveOverlayClassColor={
                updateInteractiveOverlayClassColor
              }
            />
          </div>
          <div>
            <$ModalTitle>Slides</$ModalTitle>
            <OverlaySlidesTable
              assignedWsis={selectedInteractiveOverlay.assignedWsis}
              allWsis={selectedInteractiveOverlay.allWsis}
              overrideName={selectedInteractiveOverlay.overrideName}
              originalName={selectedInteractiveOverlay.originalName}
              wsiList={wsiList}
              subprojectId={subprojectId}
              handleOverlayWsiToggled={handleInteractiveOverlayWsiToggled}
              organizationUuid={organizationUuid}
            />
          </div>
        </$ModalContent>
        <$ModalFooter>
          <Button variant="primaryOutline" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            onClick={submitInteractiveOverlayChanges}
            disabled={!didInteractiveOverlayValuesChange()}
          >
            Save changes
          </Button>
        </$ModalFooter>
      </Modal>
      <UnsavedChangesModal
        isVisible={unsavedChangesModal.isOpen}
        onClose={onUnsavedChangesModalClose}
        onDiscard={onUnsavedChangesModalDiscard}
        onSave={onUnsavedChangesModalSave}
      />
      <InteractiveOverlayDeleteConfirmationModal
        deleteInteractiveOverlayConfirmationModal={
          deleteInteractiveOverlayConfirmationModal
        }
        onInteractiveOverlayModalClose={onClose}
        setOverrideName={setOverrideName}
      />
    </>
  );
};
