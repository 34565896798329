import { filterSearch } from '@aignostics/components';
import {
  AnnotationCategoriesFilterOptions,
  AnnotationCategory,
  AnnotationCategorySet,
} from '../../../../../../types';

export const filterSetNameAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (!value) return data;
    return data.filter(({ setName }) => setName === value);
  };

export const filterSetCodeAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (!value) return data;
    return data.filter(({ setCode }) => setCode === value);
  };

export const filterModuleAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (value === '') return data;
    return data.filter(({ setModule }) =>
      value === 'no-module' ? setModule === null : setModule === value
    );
  };

export const filterSearchAnnotationCategories =
  filterSearch<AnnotationCategory>(['name'], { threshold: 0 });

export const getFilterOptions = (
  annotationCategorySets: AnnotationCategorySet[],
  selectedSetName?: string
): AnnotationCategoriesFilterOptions => {
  const selectedSet = selectedSetName
    ? annotationCategorySets.find((set) => set.name === selectedSetName)
    : null;

  return {
    setNames: annotationCategorySets.map((set) => set.name),
    setCodes: selectedSet
      ? [selectedSet.code]
      : annotationCategorySets.map((set) => set.code),
    modules: selectedSet
      ? selectedSet.modules.map((module) => module.moduleName)
      : [],
  };
};
