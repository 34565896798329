import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  InfoModal,
  Link,
  PageLayout,
  Pagination,
  PaginationInfo,
  Placeholder,
  Section,
  SortableTableHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSkeleton,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
} from '@aignostics/components';
import {
  OrganizationRole,
  OrganizationUser,
  VisibleWithScope,
} from '@aignostics/core';
import { INPUT_DEBOUNCE_MS, useDebounce } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { z } from 'zod';
import {
  SortByDirection,
  getSortDirection,
  useSetQueryParams,
  useSortBy,
} from '../../hooks';
import { Pagination as PaginationType } from '../../types';
import { capitalize } from '../../utils/capitalize';
import getQueryParams from '../../utils/getQueryParams';
import { CreateUserForm } from './Create/CreateUserForm.component';
import { useCreateUser } from './Create/useCreateUser';
import { default as FETCH_USERS_LIST } from './FETCH_USERS_LIST';

type SortByOptions = 'name' | 'email' | 'role' | 'status';

type TableHeader = {
  name: string;
  sortingBy?: SortByOptions;
};

const TABLE_HEADERS: TableHeader[] = [
  { name: 'Name', sortingBy: 'name' },
  { name: 'Email', sortingBy: 'email' },
  { name: 'Role', sortingBy: 'role' },
  { name: 'User status', sortingBy: 'status' },
];

type FilterKeys = 'filterSearchUsers' | 'filterRoles' | 'filterUserStatus';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  filterSearchUsers: { fallbackValue: '', type: 'string' },
  filterRoles: { fallbackValue: 'all', type: 'string' },
  filterUserStatus: { fallbackValue: 'all', type: 'string' },
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  filterSearchUsers: {
    icon: 'Search',
    type: 'search',
    label: 'Search',
    value: '',
    placeholder: 'Search Users…',
  },
  filterRoles: {
    icon: 'Users',
    type: 'radio',
    label: 'User role',
    value: 'all',
    options: [
      { value: 'all', label: 'All' },
      { value: 'admin', label: 'Admin' },
      { value: 'developer', label: 'Developer' },
      { value: 'editor', label: 'Editor' },
      { value: 'viewer', label: 'Viewer' },
    ],
  },
  filterUserStatus: {
    icon: 'Users',
    type: 'radio',
    label: 'User status',
    value: 'all',
    options: [
      { value: 'all', label: 'All' },
      { value: 'enabled', label: 'Enabled' },
      { value: 'disabled', label: 'Disabled' },
    ],
  },
};

const PAGE_SIZE = 20;

const $CreateUserModalWrapper = styled.div`
  padding: 32px;
`;

const $UserListTableSkeletonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => `-${theme.spacings['24']}px`};
`;

const AdminUserList = ({
  userRole,
  organizationUuid,
}: {
  userRole: OrganizationRole;
  organizationUuid: string;
}): ReactElement => {
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const createUserDialog = useDisclosure();
  const { createUser, loading: createUserLoading } = useCreateUser({
    organizationUuid,
  });
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );
  const urlsParamsSchema = z.object({
    sortBy: z.enum(['name', 'email', 'role', 'status']).nullable(),
    sortByDirection: z.enum(['asc', 'desc']).nullable(),
  });

  const { sortBy: sortByParam, sortByDirection: sortDirectionParam } =
    urlsParamsSchema.parse(
      getQueryParams(['sortBy', 'sortByDirection'] as const)
    );

  const { sortBy, setSortByOptions } = useSortBy<SortByOptions>({
    column: sortByParam as SortByOptions | null,
    sortDirection: sortDirectionParam as SortByDirection | null,
  });

  const debouncedSearchFilter = useDebounce(
    filters.filterSearchUsers,
    INPUT_DEBOUNCE_MS
  );

  const { data, error, loading } = useQuery<{
    users: PaginationType<OrganizationUser, { availableUsers: number }>;
  }>(FETCH_USERS_LIST, {
    variables: {
      page,
      pageSize: PAGE_SIZE,
      search: debouncedSearchFilter,
      roleName: filters.filterRoles,
      isDisabled: filters.filterUserStatus,
      sortBy: sortBy?.column,
      sortDirection: sortBy?.sortDirection,
    },
  });

  const users = useMemo(() => data?.users?.nodes ?? [], [data]);

  const totalUser = useMemo(
    () => data?.users?.collectionAttributes?.availableUsers ?? 0,
    [data]
  );
  const pageInfo = useMemo(
    () => data?.users?.pageInfo ?? { totalPages: 0 },
    [data]
  );

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
      ...filters,
    }),
    [page, filters, sortBy?.sortDirection, sortBy?.column]
  );

  const onSortingIconClick = (sortingBy: SortByOptions | undefined) => {
    sortingBy && setSortByOptions(sortingBy);
  };
  useSetQueryParams(queryParams);
  const theme = useTheme();

  return (
    <>
      <PageLayout
        title={'Users'}
        error={error}
        unmountWhileLoading={!loading}
        loading={loading}
      >
        <Section
          background="lighter"
          data-testid="heading-section"
          title={
            <PaginationInfo
              totalCount={totalUser}
              currentPage={page}
              itemsPerPage={PAGE_SIZE}
              itemType={pluralize('User', totalUser)}
            />
          }
        >
          <Filter
            fields={PAGE_FILTER_FIELDS}
            {...filterProps}
            onChange={(filters) => {
              filterProps.onChange(filters);
              setPage(1);
            }}
          />
          <HStack justifyContent="flex-end">
            <VisibleWithScope role={userRole} scope="user:create">
              <Button
                small
                icon="PlusCircle"
                onClick={() => {
                  createUserDialog.open();
                }}
              >
                Create User
              </Button>
            </VisibleWithScope>
          </HStack>
        </Section>
        <Section>
          {users.length === 0 && !loading ? (
            <Placeholder
              title="No users found"
              description="We could not find any matching users."
            />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEADERS.map((header) => {
                      return (
                        <SortableTableHeader
                          fixedWidth
                          key={header.name}
                          sortDirection={getSortDirection(
                            sortBy,
                            header.sortingBy
                          )}
                          onClick={() => {
                            onSortingIconClick(header.sortingBy);
                          }}
                        >
                          {header.name}
                        </SortableTableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell tooltipText={user.name || 'unknown'}>
                          <Link
                            href={user.id}
                            style={{
                              color: user.name
                                ? theme.colors.primaryLight
                                : theme.colors.linkBlue,
                            }}
                          >
                            {user.name || 'unknown'}
                          </Link>
                        </TableCell>
                        <TableCell tooltipText={user.email || 'unknown'}>
                          {user.email || 'unknown'}
                        </TableCell>
                        <TableCell tooltipText={capitalize(user.role.roleName)}>
                          {capitalize(user.role.roleName)}
                        </TableCell>
                        <TableCell>
                          {user.role.isDisabled ? 'Disabled' : 'Enabled'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {loading && (
                <$UserListTableSkeletonContainer>
                  <TableSkeleton rows={PAGE_SIZE} />
                </$UserListTableSkeletonContainer>
              )}
            </>
          )}
        </Section>
        <VStack spacing="line" alignItems={'center'}>
          {pageInfo && pageInfo?.totalPages > 1 && (
            <Pagination
              currentPage={page}
              onPageChanged={setPage}
              totalPages={pageInfo?.totalPages}
            />
          )}
        </VStack>

        <InfoModal
          title="Create new user"
          isVisible={createUserDialog.isOpen}
          onClose={createUserDialog.close}
        >
          <$CreateUserModalWrapper>
            <CreateUserForm
              onSubmit={createUser}
              isSubmitting={createUserLoading}
              currentUserRole={userRole}
            />
          </$CreateUserModalWrapper>
        </InfoModal>
      </PageLayout>
    </>
  );
};

export default AdminUserList;
