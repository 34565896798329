import {
  Header,
  HStack,
  IconButton,
  SelectSingle,
  UserAvatar,
} from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import React, {
  Dispatch,
  FunctionComponent,
  MouseEvent,
  SetStateAction,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { type SideNav } from '../../__Layout/Layout/Layout.Context';
import { UserFeedback } from '../../__Layout/UserFeedback';
import { useAuthUser } from '../../App/AuthProvider';
import { useImpersonation } from '../../App/ImpersonationProvider';
import Crumbs from '../Crumbs/Crumbs';
import { useSelectedOrganizationUuid } from '../Routes/useSelectedOrganizationUuid';
import { $AppSubHeader } from './AppHeader.styles';
import ViewToggle from './ViewToggle.component';

/**
 * Global application header for all views.
 */
const AppHeader: FunctionComponent<{
  sideNav: SideNav;
  setSideNav: Dispatch<SetStateAction<SideNav>>;
}> = ({ sideNav, setSideNav, children }) => {
  const user = useAuthUser();
  const navigate = useNavigate();
  const { impersonatedUserEmail } = useImpersonation();
  const organizations = user.roles
    .filter(({ isDisabled }) => !isDisabled)
    .map(({ organization }) => organization);
  const organizationUuid = useSelectedOrganizationUuid();
  const theme = useTheme();
  const sideNavActive = sideNav === 'menu';

  const toggleSideNav = (value: SideNav) => {
    setSideNav(sideNav === value ? null : value);
  };

  const handleUserSideNav = (event: MouseEvent) => {
    event.stopPropagation();
    setSideNav('user');
  };
  const { FULL_EXTENDED_DATA_ONBOARDING: FULL } = useViewport(
    theme.breakpoints
  );
  return (
    <div>
      <Header
        size="button"
        color={theme.colors.dark}
        actionLeft={
          <IconButton
            aria-label="Sidebar Navigation"
            description="Navigation"
            key={sideNavActive ? 'close' : 'open'}
            icon={sideNavActive ? 'X' : 'Menu'}
            onClick={() => {
              toggleSideNav('menu');
            }}
          />
        }
        actionRight={
          <HStack alignItems="center" spacing="base">
            <ViewToggle />
            {organizations.length > 1 ? (
              <SelectSingle
                id="organization"
                value={organizationUuid}
                onChange={(option) => {
                  if (option === null) {
                    throw new Error(
                      'unselecting organization is not supported'
                    );
                  }
                  const targetOrg = organizations.find(
                    ({ uuid }) => uuid === option.value
                  );
                  if (targetOrg === undefined) {
                    throw new Error(
                      `Organization ${option.value} was selected but is not available for the current user`
                    );
                  }
                  navigate(`/${targetOrg.uuid}/`);
                }}
                options={organizations.map(({ uuid, name }) => ({
                  value: uuid,
                  label: name,
                }))}
              />
            ) : null}
            <UserFeedback />
            <UserAvatar
              user={user}
              isImpersonating={impersonatedUserEmail !== null}
              onClick={handleUserSideNav}
              label="Toggle User SideBar"
            />
          </HStack>
        }
      >
        <Crumbs />
        {/* e.g. <Magnifier/> on slide view */}
        {FULL && children}
      </Header>
      {!FULL && <$AppSubHeader>{children}</$AppSubHeader>}
    </div>
  );
};

export default AppHeader;
